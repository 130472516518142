import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Typography, Card, Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { CardContent } from "semantic-ui-react";

const FileUploader = (props) => {
  const { register, handleSubmit } = useForm();
  const [image, setImage] = useState(null);
  const [inputClass, setInputClass] = useState("btn btn-primary");
  const navigate = useNavigate();

  useEffect(() => {
    if (props.imageType === "primary") {
      props.setImageType("primary");
      setInputClass("btn btn-primary");
    } else {
      props.setImageType("secondary");
      setInputClass("btn btn-info");
    }
  }, []);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleBackButton = () => {
    props.setRefreshProducts(true);
    navigate(`/admin/products`);
  };

  /* formerly used for uploading file to database
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    let productId = props.newProductId;
    formData.append("file", data.file[0]);
    formData.append("productId", productId);

    const res = await fetch(process.env.REACT_APP_API_PRODUCT_IMAGES, {
      method: "POST",
      body: formData,
    }).then((res) => res.json());
    alert(JSON.stringify(`${res.message}, status: ${res.status}`));
  };

  return (
    <div>
      <Box>
        <Typography variant="h6">Primary Image</Typography>
        <form name="primary" onSubmit={handleSubmit(onSubmit)}>
          <input type="file" {...register("file")} />

          <input type="submit" />
        </form>
      </Box>

      <Box>
        <Typography variant="h6">Secondary Image</Typography>
        <form name="secondary" onSubmit={handleSubmit(onSubmit)}>
          <input type="file" {...register("file")} />

          <input type="submit" />
        </form>
      </Box>
    </div>
  ); */
  {
    const onSubmit = async (data) => {
      const formData = new FormData();
      let productId = props.newProductId ? props.newProductId : props.productId;
      let imageType = props.imageType + "";

      formData.append("file", data.file[0]);
      formData.append("productId", productId);
      formData.append("imageType", imageType);

      if (imageType === "primary") {
        props.setImageType("secondary");
        setInputClass("btn btn-info");
      } else {
        props.setImageType("primary");
        setInputClass("btn btn-primary");
      }

      let update = props.setRefreshProducts(true);

      const res = await fetch(process.env.REACT_APP_API_PRODUCT_IMAGES, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then(setImage(null));
    };

    return (
      <div>
        <Card>
          <CardContent>
            <Typography variant="h6">Image type: {props.imageType}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="file"
                {...register("file")}
                onChange={onImageChange}
                class={inputClass}
              />
              <img
                id="target"
                src={image}
                alt=""
                onerror="this.remove()"
                className="img-thumbnail mt-2"
                height={200}
                width={200}
              />
              <input type="submit" class="btn btn-primary" />
            </form>
            <Button variant="outlined" onClick={handleBackButton}>
              Back to Products
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }
};

export default FileUploader;
