import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Typography,
  Grid,
} from "@material-ui/core";

import Slider from "../Slider/Slider";

import { ShoppingCart } from "@material-ui/icons";
import StoreIcon from "@mui/icons-material/Store";
import { Link, useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiAccount } from "@mdi/js";

import logo from "../../assets/logo-image.jpg";

import useStyles from "./styles";

const PrimarySearchAppBar = ({
  totalItems,
  sliderVisible,
  setSliderVisible,
  setSliderOff,
}) => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const classes = useStyles();
  const location = useLocation();

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          component={Link}
          to="/cart"
          aria-label="Show cart items"
          color="inherit"
        >
          <Badge badgeContent={totalItems} color="secondary">
            <ShoppingCart />
          </Badge>
        </IconButton>
        <p>Cart</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <div>
        {/* */}
        <AppBar
          className={sliderVisible ? classes.appBar : classes.appBarSmall}
          color="inherit"
          position="fixed"
        >
          <Toolbar>
            <Grid container justify="space-between">
              <Typography
                component={Link}
                to="/"
                variant="h6"
                className={classes.title}
                color="inherit"
                align="left"
              >
                <img
                  src={logo}
                  alt="Jewelry"
                  height="50px"
                  width="50px"
                  className={classes.image}
                />{" "}
                Manuel Fridman's Jewelry Shop
              </Typography>
              <Typography
                component={Link}
                to="/"
                variant="h6"
                className={classes.navigate}
                color="inherit"
                align="right"
              >
                <Icon
                  path={StoreIcon}
                  title="Products"
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color="black"
                  spin={false}
                />
                Products
              </Typography>
              <Typography
                component={Link}
                to="/contact/aboutus"
                variant="h6"
                className={classes.navigate}
                color="inherit"
                align="right"
              >
                <Icon
                  path={mdiAccount}
                  title="About Us"
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color="black"
                  spin={false}
                />
                About Us
              </Typography>
            </Grid>
            <div className={classes.grow} />
            {location.pathname === "/" && (
              <div className={classes.button}>
                <IconButton
                  component={Link}
                  to="/cart"
                  aria-label="Show cart items"
                  color="inherit"
                >
                  <Badge badgeContent={totalItems} color="secondary">
                    <ShoppingCart />
                  </Badge>
                </IconButton>
              </div>
            )}
          </Toolbar>

          {sliderVisible ? (
            <Slider
              setSliderVisible={setSliderVisible}
              setSliderOff={setSliderOff}
            />
          ) : (
            <div></div>
          )}

          {/* */}
        </AppBar>
        {/* */}
      </div>
      {renderMobileMenu}
    </>
  );
};

export default PrimarySearchAppBar;
