import React from "react";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";

import { ConfirmProvider } from "material-ui-confirm";
import FileUploader from "../FileUpload/FileUploader";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  grid: {
    width: "100%",
    margin: "100px",
  },
}));

const ProductAddWithUploader = (props) => {
  const classes = useStyles();
  return (
    <Grid container justify="center" spacing={1} className={classes.grid}>
      <Grid item md={6}>
        <Card className={classes.padding}>
          <CardHeader title="Add Product Images"></CardHeader>
          <CardContent>
            <Typography variant="h6">
              Product Id:
              {props.newProductId ? props.newProductId : props.productId}
            </Typography>
            <ConfirmProvider>
              <FileUploader
                newProductId={props.newProductId}
                productId={props.productId}
                imageType={props.imageType + ""}
                setImageType={props.setImageType}
                setRefreshProducts={props.setRefreshProducts}
              ></FileUploader>
            </ConfirmProvider>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProductAddWithUploader;
