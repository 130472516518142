import React from "react";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  CardHeader,
  FormControl,
} from "@material-ui/core";

import { useConfirm } from "material-ui-confirm";

import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const initialValues = {
  type: "",
  isActive: "true",
};

//validation schema
let validationSchema = Yup.object().shape({
  type: Yup.string().required("Required"),
  isActive: Yup.bool().required("Required"),
});

const ProductTypeAdd = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const onSubmit = async (values) => {
    //console.log(values);

    confirm({
      description: `The new product type will be saved to the database.`,
    })
      .then(() => {
        //post the values to api
        fetch(process.env.REACT_APP_API_PRODUCT_TYPES, {
          method: "POST",
          body: JSON.stringify({ ...values }),
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data) => {
            props.setRefreshTypes(true);
            props.setAction(props.actions.list);
          })
          .catch((err) => {
            console.log(err.message);
            props.setAction(props.actions.list);
          });
      })
      .catch(() => console.log("Action cancelled."));
  };

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item md={6}>
        <Card className={classes.padding}>
          <CardHeader title="Add Product Type"></CardHeader>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ dirty, isValid, values, handleChange, handleBlur }) => {
              return (
                <Form>
                  <CardContent>
                    <Grid container spacing={1} justify="center">
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Product Type"
                          variant="outlined"
                          fullWidth
                          name="type"
                          value={values.type}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="select-outlined-label">
                            Is Active
                          </InputLabel>
                          <Select
                            labelId="select-outlined-label"
                            id="select-outlined"
                            label="Is Active"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isActive}
                            name="isActive"
                          >
                            <MenuItem value="true">
                              <em>true</em>
                            </MenuItem>
                            <MenuItem value="false">
                              <em>false</em>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/* //// */}
                    <CardActions>
                      <Button
                        component={Link}
                        variant="outlined"
                        to="/admin/producttypes"
                        onClick={() => {
                          props.setAction(props.actions.list);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!dirty || !isValid}
                        variant="contained"
                        color="primary"
                        type="Submit"
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </CardActions>
                  </CardContent>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProductTypeAdd;
