import React from "react";
import { CssBaseline, Paper, Typography } from "@material-ui/core";
import useStyles from "./styles";

const PayCancel = () => {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <div className={classes.toolbar} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h4" align="center">
            Oops! Your payment has been cancelled.
          </Typography>
          <p></p>
          <p></p>
          <Typography variant="h5" align="center">
            We appreciate your business!
          </Typography>
          <Typography variant="h5" align="center">
            If you have any questions, please contact us.
          </Typography>
        </Paper>
      </main>
    </>
  );
};

export default PayCancel;
