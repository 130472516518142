import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
} from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";

import useStyles from "./styles";

const Product = ({ product, onAddToCart, setSelectedProduct }) => {
  const classes = useStyles();

  useEffect(() => {
    //setSelectedProduct(product);
  }, []);

  const handleAddToCart = () => onAddToCart(product.ProductId, 1);

  const handleSetSelected = () => {
    setSelectedProduct(product);
  };

  let imageUrl = process.env.REACT_APP_API_HOST + product.PrimaryImageUrl;

  return (
    <Link to={"/products/productdetails"} onClick={handleSetSelected}>
      <Card
        mt="10%"
        className={classes.root}
        sx={{
          width: 400,
          height: 400,

          backgroundColor: "primary.dark",
          transition: "transform 0.15s ease-in-out",
          "&:hover": {
            backgroundColor: "primary.main",
            opacity: [0.9, 0.8, 0.7],
            transform: "scale3d(1.05, 1.05, 1)",
          },
        }}
      >
        <CardMedia
          className={classes.media}
          image={imageUrl}
          title={product.ProductName}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
        />
        <CardContent>
          <div className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {product.ProductName}
            </Typography>
            <Typography gutterBottom variant="h5" component="h2">
              ${product.Price}
            </Typography>
          </div>
          <Typography
            dangerouslySetInnerHTML={{ __html: product.Description }}
            // variant="body2"
            variant="subtitle1"
            color="textSecondary"
            component="p"
          />
        </CardContent>

        <CardActions disableSpacing className={classes.cardActions}>
          <IconButton aria-label="Add to Cart" onClick={handleAddToCart}>
            <AddShoppingCart />
          </IconButton>
        </CardActions>
      </Card>
    </Link>
  );
};

export default Product;
