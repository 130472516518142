import React, { useState } from "react";

import {
  Typography,
  Divider,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  FormControl,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import useStyles from "./styles";

const ProductLengthList = (props) => {
  const classes = useStyles();

  const [editEnabled, setEditEnabled] = useState(false);
  const [selectedLengthId, setSelectedLengthId] = useState(0);

  const handleLengthChange = (event) => {
    setEditEnabled(true);
    setSelectedLengthId(event.target.value);
  };

  const handleAddNew = () => {
    props.setAction(props.actions.add);
  };

  const handleEditLength = (event) => {
    var selected = getSelectedLength();
    props.setSelectedLength(selected);
    props.setAction(props.actions.update);
  };

  const getSelectedLength = () => {
    var selected;
    var id = getSelectedLengthId();
    selected = props.productLengths.filter(
      (selectedLength) => selectedLength.LengthId === id
    );
    return selected;
  };

  const getSelectedLengthId = () => {
    if (selectedLengthId == null) {
      return null;
    } else if (selectedLengthId != null) {
      return selectedLengthId;
    }
  };

  return (
    <Card className={classes.root}>
      {/* <CardHeader title="Product Colors"></CardHeader> */}
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <p></p>
          <Typography variant="h6">Product Lengths</Typography>
          <Select
            width="150px;"
            variant="standard"
            autoWidth={true}
            labelId="length-select-label"
            id="select-select"
            value={selectedLengthId}
            label="Product Lengths"
            onChange={handleLengthChange}
          >
            <MenuItem disabled value="">
              <em>Please Select</em>
            </MenuItem>
            {props.productLengths.map((item) => {
              if (item.LengthId != null) {
                return (
                  <MenuItem value={item.LengthId} key={item.LengthId} fullWidth>
                    {item.Description}
                  </MenuItem>
                );
              } else {
                return null;
              }
            })}
          </Select>
        </FormControl>

        <Divider variant="middle"></Divider>
        <div style={{ height: "40px" }}></div>
        <Typography variant="h6">
          Add a new length or edit a selected length.
        </Typography>
        <p />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleAddNew}
          >
            Add New
          </Button>
          <Button
            disabled={!editEnabled}
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleEditLength}
          >
            Edit Selected
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProductLengthList;
