import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
  Divider,
  Button,
} from "@material-ui/core";

import { Link, useNavigate } from "react-router-dom";

import { commerce } from "../../../lib/commerce";

import { Helmet } from "react-helmet";

import Review from "../Review";

import useStyles from "./styles";

const Checkout = ({ cart, setCheckedCart, onCaptureCheckout }) => {
  const classes = useStyles();

  const [checkedCartLocal, setCheckedCartLocal] = useState(null);
  const checkCart = async () => {
    //retrieve cart data
    //post the values to api
    let filteredData = await fetch(
      process.env.REACT_APP_API_CART_AVAILABILITY_CHECK,
      {
        method: "POST",
        body: JSON.stringify({ cart }),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Cart data is: " + JSON.stringify(data));

        setCheckedCart(data);
        setCheckedCartLocal(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    checkCart();
  }, []);

  if (checkedCartLocal != null) {
    return (
      <>
        <Helmet>
          <title>Manuel Fridman's Jewelry Checkout</title>
          <meta name="description" content="Jewelry shop checkout" />
        </Helmet>
        <CssBaseline />
        <div className={classes.toolbar} />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="h4" align="center">
              Checkout
            </Typography>

            <Review
              cart={cart}
              checkedCart={checkedCartLocal}
              onCaptureCheckout={onCaptureCheckout}
            ></Review>
          </Paper>
        </main>
      </>
    );
  }
};

export default Checkout;
