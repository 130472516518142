import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@material-ui/core/Box";
//import CurrencyFieldATM from "./CurrencyFieldATM";
import CurrencyFieldText from "./CurrencyFieldText";

import {
  Grid,
  Typography,
  makeStyles,
  Card,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  CardHeader,
  FormControl,
} from "@material-ui/core";

import { useConfirm } from "material-ui-confirm";

import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

import ShoppingOptions from "../../../components/ShoppingOptions/ShoppingOptions";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const ProductAdd = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const confirm = useConfirm();

  //------ State ----------------
  const [productTypeId, setProductTypeId] = useState(
    process.env.REACT_APP_PRODUCT_TYPE_ID_MAIN
  );
  const [categoryId, setCategoryId] = useState("");
  const [colorId, setColorId] = useState("");
  const [metalId, setMetalId] = useState("");
  const [gemstoneId, setGemstoneId] = useState("");
  const [pearlTypeId, setPearlTypeId] = useState("");
  const [pearlSizeId, setPearlSizeId] = useState("");
  const [lengthId, setLengthId] = useState("");
  //const [ringSizeId, setRingSizeId] = useState("");
  const [productEraId, setProductEraId] = useState("");
  const [discountId, setDiscountId] = useState("");
  //-----------------------------
  const [newProductId, setNewProductId] = useState("");
  //------ End State ------------
  const getSKU = () => {
    return Math.floor(1000000 + Math.random() * 9000000);
  };

  const initialValues = {
    //required
    productTypeId: productTypeId,
    categoryId: categoryId,
    //options
    colorId: colorId,
    metalId: metalId,
    gemstoneId: gemstoneId,
    pearlTypeId: pearlTypeId,
    pearlSizeId: pearlSizeId,
    lengthId: lengthId,
    //ringSizeId: ringSizeId,
    productEraId: productEraId,
    discountId: discountId,
    //product fields
    productName: "",
    shortDescription: "",
    description: "",
    designer: null,
    slug: "",
    sku: getSKU(),
    price: "",
    currency: "CAD",
    quantity: "",
    size: null,
    comments: null,
    isActive: "true",
  };

  //validation schema
  let validationSchema = Yup.object().shape({
    productTypeId: Yup.number().required("Required"),
    categoryId: Yup.number().required("Required"),
    productName: Yup.string().required("Required"),
    shortDescription: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    slug: Yup.string().required("Required"),
    sku: Yup.number().required("Required"),
    price: Yup.string().required("Required"),
    currency: Yup.string().required("Required"),
    quantity: Yup.string().required("Required"),
    isActive: Yup.bool().required("Required"),
  });

  const onSubmit = async (values) => {
    confirm({
      description: `The new product will be saved to the database.`,
    })
      .then(() => {
        //post the values to api
        fetch(process.env.REACT_APP_API_PRODUCT_ADD, {
          method: "POST",
          body: JSON.stringify({ ...values }),
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data) => {
            setNewProductId(data.new_identity);
            handleAddProduct(data.new_identity);
          })
          .catch((err) => {
            console.log(err.message);
          });
        props.setRefreshProducts(true);
      })
      .catch(() => console.log("Action cancelled."));
  };

  const handleAddProduct = async (id) => {
    props.setNewProductId(id);
    props.setRefreshProducts(true);
    navigate(`/admin/products/addimages`);
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 8fr",
      }}
    >
      <Grid container justify="center" spacing={4}>
        <Grid item>
          <ShoppingOptions
            productTypes={props.productTypes}
            productTypeId={productTypeId}
            setProductTypeId={setProductTypeId}
            //--------------------------------
            categories={props.categories}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            //--------------------------------
            productColors={props.productColors}
            colorId={colorId}
            setColorId={setColorId}
            //--------------------------------
            productMetals={props.productMetals}
            metalId={metalId}
            setMetalId={setMetalId}
            //--------------------------------
            productGemstones={props.productGemstones}
            gemstoneId={gemstoneId}
            setGemstoneId={setGemstoneId}
            //--------------------------------
            pearlTypes={props.pearlTypes}
            pearlTypeId={pearlTypeId}
            setPearlTypeId={setPearlTypeId}
            //--------------------------------
            pearlSizes={props.pearlSizes}
            pearlSizeId={pearlSizeId}
            setPearlSizeId={setPearlSizeId}
            //--------------------------------
            productLengths={props.productLengths}
            lengthId={lengthId}
            setLengthId={setLengthId}
            //--------------------------------
            productEras={props.productEras}
            productEraId={productEraId}
            setProductEraId={setProductEraId}
            //--------------------------------
            discounts={props.discounts}
            discountId={discountId}
            setDiscountId={setDiscountId}
            // prevent handleFiltersChange call
            admin={true}
          ></ShoppingOptions>
        </Grid>
      </Grid>

      <Card className={classes.padding}>
        <CardHeader title="Add Product"></CardHeader>
        <Typography variant="h8" display="block">
          Please note that the options should be selected first, before entering
          product specific details. Among the options, Product Type and Category
          are required, any other field is optional.
        </Typography>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ dirty, isValid, values, handleChange, handleBlur }) => {
            return (
              <Form>
                <CardContent>
                  <Grid container spacing={4} justify="center">
                    <Grid item xs={3}>
                      <Field
                        label="Product Name"
                        variant="outlined"
                        fullWidth
                        name="productName"
                        value={values.productName}
                        component={TextField}
                        multiline={true}
                      />
                    </Grid>
                    {/*-------------------*/}
                    <Grid item xs={4}>
                      <Field
                        label="Short Description"
                        variant="outlined"
                        fullWidth
                        name="shortDescription"
                        value={values.shortDescription}
                        component={TextField}
                        multiline={true}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        label="Description"
                        variant="outlined"
                        fullWidth
                        name="description"
                        value={values.description}
                        component={TextField}
                        multiline={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        label="Size (CT WT)"
                        variant="outlined"
                        fullWidth
                        name="size"
                        value={values.size}
                        component={TextField}
                        multiline={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        label="Designer"
                        variant="outlined"
                        fullWidth
                        name="designer"
                        value={values.designer}
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        label="Comments"
                        variant="outlined"
                        fullWidth
                        name="comments"
                        value={values.comments}
                        component={TextField}
                        multiline={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        label="Slug"
                        variant="outlined"
                        fullWidth
                        name="slug"
                        value={values.slug}
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        label="SKU"
                        variant="outlined"
                        fullWidth
                        name="sku"
                        value={values.sku}
                        component={TextField}
                      />
                    </Grid>

                    {/*-------------------------------*/}
                    <Grid item xs={1}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="select-outlined-label">
                          Is Active
                        </InputLabel>
                        <Select
                          labelId="select-outlined-label"
                          id="select-outlined"
                          label="Is Active"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.isActive}
                          name="isActive"
                        >
                          <MenuItem value="true">
                            <em>true</em>
                          </MenuItem>
                          <MenuItem value="false">
                            <em>false</em>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* //// */}
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={"2%"}
                  >
                    <Grid
                      container
                      justify="center"
                      spacing={1}
                      className={classes.grid}
                    >
                      <Grid item xs={1}>
                        {/*                         <Field
                          label="Price"
                          variant="outlined"
                          fullWidth
                          name="price"
                          value={values.price}
                          component={TextField}
                        /> */}
                        <CurrencyFieldText
                          label="Amount"
                          name="price"
                          onChange={handleChange}
                          value={values.price}
                          currencySymbol="$"
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Field
                          label="Currency"
                          variant="outlined"
                          fullWidth
                          name="currency"
                          value={values.currency}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Field
                          label="Quantity"
                          variant="outlined"
                          fullWidth
                          name="quantity"
                          value={values.quantity}
                          component={TextField}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {/* /// */}
                  <Grid
                    container
                    justify="center"
                    spacing={1}
                    className={classes.grid}
                  ></Grid>
                  {/* /// */}
                  <CardActions>
                    <Button
                      component={Link}
                      variant="outlined"
                      to="/admin/producttypes"
                      onClick={() => {
                        props.setAction(props.actions.list);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!dirty || !isValid}
                      variant="contained"
                      color="primary"
                      type="Submit"
                      className={classes.button}
                    >
                      Save
                    </Button>
                  </CardActions>
                </CardContent>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </div>
  );
};

export default ProductAdd;
