import { loadStripe } from "@stripe/stripe-js";
import countries from "./countries.json";
import subdivisions from "./subdivisions.json";
import shipping from "./shipping.json";

class Cart {
  constructor() {
    this.items = [];
  }

  async retrieve() {
    //look into the localstorage for the cart token
    let token = localStorage.getItem(process.env.REACT_APP_API_CART_KEY);
    if (token === null) return null;
    //
    //retrieve cart data
    //get the values from api
    let fetch_url = process.env.REACT_APP_API_CART_RETRIEVE + "?token=" + token;
    await fetch(fetch_url)
      .then((response) => response.json())
      .then((data) => {
        console.log("Cart data: " + JSON.stringify(data));
        //alert("Cart token: " + data[0].Token);
        //todo: check if there is any data
        //if there is data, data[0] will contain cart specific info

        //the cart items
        //todo: check if there are any items
        cart.total_items = data.length;
        //using map for items itteration
        data.map((itm, index) => {
          if (index === 0) {
            cart.cartId = itm.CartId;
            cart.token = itm.Token;
            cart.total = itm.Total;
            cart.status = itm.Status;
            cart.userId = itm.UserId;
            cart.userAddressId = itm.UserAddressId;
            cart.dateCreated = itm.DateCreated;
          }
          var item = new CartItem();
          item.cartItemId = itm.CartItemId;
          item.itemTocken = itm.ItemToken;
          item.quantity = itm.Quantity;
          item.total = "$" + item.quantity * itm.Price;
          item.productName = itm.ProductName;
          item.sku = itm.SKU;
          item.imageUrl = itm.PrimaryImageUrl.trim();
          cart.items.push(item);
        });

        console.log("Cart object created... " + JSON.stringify(cart));
      })
      .catch((err) => {
        console.log(err.message);
      });

    return cart;
  }

  /*   async checkCart(cartdata) {
    let filteredData = null;
    //retrieve cart data
    //post the values to api
    await fetch(process.env.REACT_APP_API_CART_AVAILABILITY_CHECK, {
      method: "POST",
      body: JSON.stringify({ cartdata }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Cart data: " + JSON.stringify(data));
        filteredData = data;
      })
      .catch((err) => {
        console.log(err.message);
      });

    return filteredData;
  } */

  async update(itemId, quantity) {
    console.log("Update itemId: " + itemId + " with: " + quantity);
    cart = new Cart();
    var values = {
      itemId: itemId,
      quantity: quantity,
    };
    //post the values to api
    await fetch(process.env.REACT_APP_API_CART_UPDATE, {
      method: "POST",
      body: JSON.stringify({ ...values }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Cart data: " + JSON.stringify(data));
        //if there is data, data[0] will contain cart specific info

        //the cart items
        //todo: check if there are any items
        cart.total_items = data.length;
        if (cart.total_items === 0) {
          //remove the cart key from localStorage
          localStorage.removeItem(process.env.REACT_APP_API_CART_KEY);
          localStorage.removeItem(process.env.REACT_APP_API_CART_CONTENT);
          cart = new Cart();
        } else {
          //using map for items itteration
          data.map((itm, index) => {
            if (index === 0) {
              cart.cartId = itm.CartId;
              cart.token = itm.Token;
              cart.total = itm.Total;
              cart.status = itm.Status;
              cart.userId = itm.UserId;
              cart.userAddressId = itm.UserAddressId;
              cart.dateCreated = itm.DateCreated;
            }
            var item = new CartItem();
            item.cartItemId = itm.CartItemId;
            item.itemTocken = itm.ItemToken;
            item.quantity = itm.Quantity;
            item.total = "$" + item.quantity * itm.Price;
            item.productName = itm.ProductName;
            item.sku = itm.SKU;
            item.imageUrl = itm.PrimaryImageUrl.trim();
            cart.items.push(item);
          });
        }

        console.log("Cart object created... " + JSON.stringify(cart));
      })
      .catch((err) => {
        console.log(err.message);
      });

    return cart;
  }

  async add(productId, quantity) {
    //let token = "cart_12345678912345";
    let token = localStorage.getItem(process.env.REACT_APP_API_CART_KEY);
    let new_token = token ? false : true;
    if (token === null) {
      //generate the cart token
      token =
        "cart_" + Math.floor(10000000000000 + Math.random() * 90000000000000);
      console.log("Cart Token: " + token);
    }
    console.log("Add ProductId: " + productId + " with: " + quantity);
    cart = new Cart();
    var values = {
      token: token,
      new_token: new_token,
      productId: productId,
      quantity: quantity,
    };
    //post the values to api
    await fetch(process.env.REACT_APP_API_CART_ADD, {
      method: "POST",
      body: JSON.stringify({ ...values }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Cart data: " + JSON.stringify(data));
        //todo: check if there is any data
        //if there is data, data[0] will contain cart specific info

        //the new cart token to be stored locally
        if (new_token) {
          localStorage.setItem(
            process.env.REACT_APP_API_CART_KEY,
            data[0].Token
          );
        }
        //the cart items
        //todo: check if there are any items
        cart.total_items = data.length;
        //using map for items itteration
        data.map((itm, index) => {
          if (index === 0) {
            cart.cartId = itm.CartId;
            cart.token = itm.Token;
            cart.total = itm.Total;
            cart.status = itm.Status;
            cart.userId = itm.UserId;
            cart.userAddressId = itm.UserAddressId;
            cart.dateCreated = itm.DateCreated;
          }
          var item = new CartItem();
          item.cartItemId = itm.CartItemId;
          item.itemTocken = itm.ItemToken;
          item.quantity = itm.Quantity;
          item.total = "$" + item.quantity * itm.Price;
          item.productName = itm.ProductName;
          item.sku = itm.SKU;
          item.imageUrl = itm.PrimaryImageUrl.trim();
          cart.items.push(item);
        });

        console.log("Cart object created... " + JSON.stringify(cart));
      })
      .catch((err) => {
        console.log(err.message);
      });

    return cart;
  }

  async empty() {
    //empty cart
    let token = localStorage.getItem(process.env.REACT_APP_API_CART_KEY);
    //get the values from api
    let fetch_url = process.env.REACT_APP_API_CART_EMPTY + "?token=" + token;
    await fetch(fetch_url)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err.message);
      });

    //remove token from localStorage
    localStorage.removeItem(process.env.REACT_APP_API_CART_KEY);
    localStorage.removeItem(process.env.REACT_APP_API_CART_CONTENT);
    cart = new Cart();
    return cart;
  }
}

//////////////////////////////////////////////////////////////////////

class CartItem {
  constructor() {
    this.cartItemId = 0;
    this.itemTocken = "";
    this.quantity = "";
    this.productName = "";
    this.sku = "";
    this.imageUrl = "";
  }
}

class Services {
  constructor() {
    this.countries = countries;
    this.subdivisions = subdivisions;
  }

  localeListShippingCountries() {
    try {
      var json = JSON.parse(JSON.stringify(this.countries));
      return json;
    } catch (error) {
      return null;
    }
  }
  localeListSubdivisions() {
    try {
      var json = JSON.parse(JSON.stringify(this.subdivisions));
      return json;
    } catch (error) {
      return null;
    }
  }
}

class Checkout {
  constructor() {
    this.shipping = shipping;
  }

  getShippingOptions(checkoutTokenId, { country, region: stateProvince }) {
    try {
      var json = JSON.parse(JSON.stringify(this.shipping));
      return json;
    } catch (error) {
      return null;
    }
  }

  async capture(cart) {
    let token = localStorage.getItem(process.env.REACT_APP_API_CART_KEY);
    //post the values to api
    await fetch(process.env.REACT_APP_API_STRIPE_PAY, {
      method: "POST",
      body: JSON.stringify({ cart }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response data: " + JSON.stringify(data));
      })
      .catch((err) => {
        console.log(err.message);
      });

    return;
  }

  async makePayment(cart) {
    const stripe = await loadStripe(
      //"pk_test_51LDDqKFziW4CiGmbr3A2D8WXsq0rW4MQIo3UiQN2dlciuOaQHvJOB22GNQnZNzmnMbHod8J5wYmnyw9FGOmNxiKm00ntXdutQb"
      process.env.REACT_APP_STRIPE_PUBLIC_KEY
    );
    const body = { cart };
    const headers = {
      "Content-Type": "application/json",
    };

    const response = await fetch(process.env.REACT_APP_API_STRIPE_PAY, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    const session = await response.json();

    const result = stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.error);
    }
  }
}

let cart = new Cart();

class Commerce {
  constructor() {
    this.cart = new Cart();
    this.services = new Services();
    this.checkout = new Checkout();
  }
}

export const commerce = new Commerce();
