import React, { useState, useEffect } from "react";
import { Divider } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import useStyles from "./styles";

const ShoppingOptions = (props) => {
  const classes = useStyles();
  //---Nested menu items related
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    return setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const setCategorySelectValue = (value) => {
    props.setCategoryId(value);
    //---- FILTERS ----
    if (!props.admin) {
      props.handleFiltersChange("categoryId", value);
    }
    handleClose();
  };
  //----------------------------

  const handleTypeChange = (event) => {
    props.setProductTypeId(event.target.value);
  };

  const handleCategoryChange = (event) => {
    if (event.target.value === "0") {
    } else {
      props.setCategoryId(event.target.value);
    }
    handleClose();
  };

  const handleColorChange = (event) => {
    if (event.target.value === "0") {
      setColorId("0");
    }
    if (props.admin) {
      props.setColorId(event.target.value);
    } else {
      setColorId(event.target.value);
      props.handleFiltersChange("colorId", event.target.value);
    }
  };
  const handleMetalChange = (event) => {
    if (event.target.value === "0") {
      setMetalId("0");
    }
    if (props.admin) {
      props.setMetalId(event.target.value);
    } else {
      setMetalId(event.target.value);
      props.handleFiltersChange("metalId", event.target.value);
    }
  };
  const handleGemstoneChange = (event) => {
    if (event.target.value === "0") {
      setGemstoneId("0");
    }
    if (props.admin) {
      props.setGemstoneId(event.target.value);
    } else {
      setGemstoneId(event.target.value);
      props.handleFiltersChange("gemstoneId", event.target.value);
    }
  };
  const handlePearlTypeChange = (event) => {
    if (event.target.value === "0") {
      setPearlTypeId("0");
    }
    if (props.admin) {
      props.setPearlTypeId(event.target.value);
    } else {
      setPearlTypeId(event.target.value);
      props.handleFiltersChange("pearlTypeId", event.target.value);
    }
  };
  const handlePearlSizeChange = (event) => {
    if (event.target.value === "0") {
      setPearlSizeId("0");
    }
    if (props.admin) {
      props.setPearlSizeId(event.target.value);
    } else {
      setPearlSizeId(event.target.value);
      props.handleFiltersChange("pearlSizeId", event.target.value);
    }
  };
  const handleLengthChange = (event) => {
    if (event.target.value === "0") {
      setLengthId("0");
    }
    if (props.admin) {
      props.setLengthId(event.target.value);
    } else {
      setLengthId(event.target.value);
      props.handleFiltersChange("lengthId", event.target.value);
    }
  };
  const handleEraChange = (event) => {
    if (event.target.value === "0") {
      setProductEraId("0");
    }
    if (props.admin) {
      props.setProductEraId(event.target.value);
    } else {
      setProductEraId(event.target.value);
      props.handleFiltersChange("eraId", event.target.value);
    }
  };
  const handleDiscountChange = (event) => {
    if (event.target.value === "0") {
      setDiscountId("0");
    }
    if (props.admin) {
      props.setDiscountId(event.target.value);
    } else {
      setDiscountId(event.target.value);
      props.handleFiltersChange("discountId", event.target.value);
    }
  };
  //------------------------------------

  const handleItemOver = (event) => {
    if (categoryMenuPosition) {
      return;
    }
    event.preventDefault();
    setCategoryMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const [categoryMenuPosition, setCategoryMenuPosition] = useState(null);

  //-------------------drop-downs----------------------
  const [colorId, setColorId] = useState("");
  const [lengthId, setLengthId] = useState("");
  const [metalId, setMetalId] = useState("");
  const [gemstoneId, setGemstoneId] = useState("");
  const [pearlTypeId, setPearlTypeId] = useState("");
  const [pearlSizeId, setPearlSizeId] = useState("");
  const [productEraId, setProductEraId] = useState("");
  const [discountId, setDiscountId] = useState("");
  //---------------------------------------------

  useEffect(() => {
    if (props.filterRemoved === "colorId") {
      setColorId(0);
    } else if (props.filterRemoved === "lengthId") {
      setLengthId(0);
    } else if (props.filterRemoved === "metalId") {
      setMetalId(0);
    } else if (props.filterRemoved === "gemstoneId") {
      setGemstoneId(0);
    } else if (props.filterRemoved === "pearlTypeId") {
      setPearlTypeId(0);
    } else if (props.filterRemoved === "pearlSizeId") {
      setPearlSizeId(0);
    } else if (props.filterRemoved === "eraId") {
      setProductEraId(0);
    } else if (props.filterRemoved === "discountId") {
      setDiscountId(0);
    }
  });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <FormControl style={{ width: 160 }} sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="type-select-helper-label">Product Type</InputLabel>
        <Select
          labelId="type-select-helper-label"
          id="type-select-helper"
          value={props.productTypeId + ""}
          defaultValue={props.productTypeId + ""}
          label="Product Type"
          onChange={handleTypeChange}
        >
          {props.productTypes.map((item) => {
            //return only active types
            if (props.admin) {
              return (
                <MenuItem
                  value={item.ProductTypeId}
                  key={item.ProductTypeId}
                  fullWidth
                >
                  {item.Type}
                </MenuItem>
              );
            } else if (item.IsActive) {
              return (
                <MenuItem
                  value={item.ProductTypeId}
                  key={item.ProductTypeId}
                  fullWidth
                >
                  {item.Type}
                </MenuItem>
              );
            }
          })}
        </Select>
        <FormHelperText>Products by type</FormHelperText>
      </FormControl>
      <Divider />
      <FormControl style={{ width: 160 }} sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="category-select-helper-label">Category</InputLabel>
        <Select
          label={"Category"}
          value={props.categoryId + ""}
          open={open}
          onOpen={handleClick}
          onClose={handleClose}
          onChange={handleCategoryChange}
        >
          <MenuItem
            value={"0"}
            onClick={(e) => {
              setCategorySelectValue(e.currentTarget.dataset.value);
            }}
          >
            <em>No Filter</em>
          </MenuItem>
          {props.categories.map((item) => {
            if (item.IsActive)
              return (
                <MenuItem
                  value={
                    props.filterRemoved === "categoryId" ? "0" : item.CategoryId
                  }
                  key={
                    props.filterRemoved === "categoryId" ? "0" : item.CategoryId
                  }
                  sx={{
                    display: "none",
                  }}
                >
                  {item.Title}
                </MenuItem>
              );
          })}
          {props.categories.map((item) => {
            if (item.ParentId === null) {
              let cats = props.categories;
              let subcats = [];
              for (let i = 0; i < cats.length; i++) {
                if (
                  cats[i].ParentId != null &&
                  cats[i].ParentId === item.CategoryId
                ) {
                  if (props.admin) {
                    subcats.push(
                      <MenuItem
                        label={cats[i].Title}
                        data-value={cats[i].CategoryId}
                        key={cats[i].CategoryId}
                        fullWidth
                        parentMenuOpen={!!categoryMenuPosition}
                        onMouseOver={handleItemOver}
                        onClick={(e) => {
                          setCategorySelectValue(e.currentTarget.dataset.value);
                        }}
                      >
                        {cats[i].Title}
                      </MenuItem>
                    );
                  } else if (cats[i].IsActive) {
                    subcats.push(
                      <MenuItem
                        label={cats[i].Title}
                        data-value={cats[i].CategoryId}
                        key={cats[i].CategoryId}
                        fullWidth
                        parentMenuOpen={!!categoryMenuPosition}
                        onMouseOver={handleItemOver}
                        onClick={(e) => {
                          setCategorySelectValue(e.currentTarget.dataset.value);
                        }}
                      >
                        {cats[i].Title}
                      </MenuItem>
                    );
                  }
                }
              }
              if (props.admin) {
                return (
                  <NestedMenuItem
                    fullWidth
                    label={item.Title}
                    parentMenuOpen={open}
                  >
                    {item.Title}
                    {subcats}
                  </NestedMenuItem>
                );
              } else if (item.IsActive) {
                return (
                  <NestedMenuItem
                    fullWidth
                    label={item.Title}
                    parentMenuOpen={open}
                  >
                    {item.Title}
                    {subcats}
                  </NestedMenuItem>
                );
              }
            }
          })}
        </Select>
        <FormHelperText>Products by category</FormHelperText>
      </FormControl>
      <Divider />
      {/* HIDDEN FILTERS */}

      <div hidden={!props.admin}>
        <FormControl style={{ width: 160 }} sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="category-select-helper-label">Color</InputLabel>
          <Select
            label={"Color"}
            //value={props.colorId + ""}
            value={props.admin ? props.colorId + "" : colorId}
            onChange={handleColorChange}
          >
            <MenuItem
              value={"0"}
              onClick={(e) => {
                handleColorChange(e);
              }}
            >
              <em>No Filter</em>
            </MenuItem>
            {props.productColors.map((item) => {
              return (
                <MenuItem
                  value={props.filterRemoved === "colorId" ? "0" : item.ColorId}
                  key={props.filterRemoved === "colorId" ? "0" : item.ColorId}
                  fullWidth
                >
                  {item.ColorName}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Products by color</FormHelperText>
        </FormControl>
        <Divider />
        <FormControl style={{ width: 160 }} sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="metal-select-helper-label">Product Metal</InputLabel>
          <Select
            labelId="metal-select-helper-label"
            id="metal-select-helper"
            //value={props.metalId + ""}
            value={props.admin ? props.metalId + "" : metalId}
            label="Product Metals"
            onChange={handleMetalChange}
          >
            <MenuItem
              value={"0"}
              onClick={(e) => {
                handleMetalChange(e);
              }}
            >
              <em>No Filter</em>
            </MenuItem>
            {props.productMetals.map((item) => {
              return (
                <MenuItem
                  value={props.filterRemoved === "metalId" ? "0" : item.MetalId}
                  key={props.filterRemoved === "metalId" ? "0" : item.MetalId}
                  fullWidth
                >
                  {item.Description}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Products by metal</FormHelperText>
        </FormControl>
        <Divider />
        <FormControl style={{ width: 160 }} sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="gemstone-select-helper-label">Gemstone</InputLabel>
          <Select
            labelId="gemstone-select-helper-label"
            id="gemstone-select-helper"
            //value={props.gemstoneId + ""}
            value={props.admin ? props.gemstoneId + "" : gemstoneId}
            label="Product Gemstones"
            onChange={handleGemstoneChange}
          >
            <MenuItem
              value={"0"}
              onClick={(e) => {
                handleGemstoneChange(e);
              }}
            >
              <em>No Filter</em>
            </MenuItem>
            {props.productGemstones.map((item) => {
              return (
                <MenuItem
                  value={
                    props.filterRemoved === "gemstoneId" ? "0" : item.GemstoneId
                  }
                  key={
                    props.filterRemoved === "gemstoneId" ? "0" : item.GemstoneId
                  }
                  fullWidth
                >
                  {item.Description}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Products by gemstone</FormHelperText>
        </FormControl>
        <Divider />
        <FormControl style={{ width: 160 }} sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="pearltype-select-helper-label">Pearl Type</InputLabel>
          <Select
            labelId="pearltype-select-helper-label"
            id="pearltype-select-helper"
            //value={props.pearlTypeId + ""}
            value={props.admin ? props.pearlTypeId + "" : pearlTypeId}
            label="Pearl Type"
            onChange={handlePearlTypeChange}
          >
            <MenuItem
              value={"0"}
              onClick={(e) => {
                handlePearlTypeChange(e);
              }}
            >
              <em>No Filter</em>
            </MenuItem>
            {props.pearlTypes.map((item) => {
              return (
                <MenuItem
                  value={
                    props.filterRemoved === "pearlTypeId" ? "0" : item.TypeId
                  }
                  key={
                    props.filterRemoved === "pearlTypeId" ? "0" : item.TypeId
                  }
                  fullWidth
                >
                  {item.Description}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Products by pearl type</FormHelperText>
        </FormControl>
        <Divider />
        <FormControl style={{ width: 160 }} sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="pearlsize-select-helper-label">Pearl Size</InputLabel>
          <Select
            labelId="pearlsize-select-helper-label"
            id="pearlsize-select-helper"
            //value={props.pearlSizeId + ""}
            value={props.admin ? props.pearlSizeId + "" : pearlSizeId}
            label="Pearl Size"
            onChange={handlePearlSizeChange}
          >
            <MenuItem
              value={"0"}
              onClick={(e) => {
                handlePearlSizeChange(e);
              }}
            >
              <em>No Filter</em>
            </MenuItem>
            {props.pearlSizes.map((item) => {
              return (
                <MenuItem
                  value={
                    props.filterRemoved === "pearlSizeId"
                      ? "0"
                      : item.PearlSizeId
                  }
                  key={
                    props.filterRemoved === "pearlSizeId"
                      ? "0"
                      : item.PearlSizeId
                  }
                  fullWidth
                >
                  {item.Size}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Products by pearl size</FormHelperText>
        </FormControl>
        <Divider />
        <FormControl style={{ width: 160 }} sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="length-select-helper-label">Length</InputLabel>
          <Select
            labelId="length-select-helper-label"
            id="length-select-helper"
            //value={props.lengthId + ""}
            value={props.admin ? props.lengthId + "" : lengthId}
            label="Length"
            onChange={handleLengthChange}
          >
            <MenuItem
              value={"0"}
              onClick={(e) => {
                handleLengthChange(e);
              }}
            >
              <em>No Filter</em>
            </MenuItem>
            {props.productLengths.map((item) => {
              return (
                <MenuItem
                  value={
                    props.filterRemoved === "lengthId"
                      ? "0"
                      : item.LengthId + ""
                  }
                  key={
                    props.filterRemoved === "lengthId"
                      ? "0"
                      : item.LengthId + ""
                  }
                  fullWidth
                >
                  {item.Description}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Products by length</FormHelperText>
        </FormControl>

        {/* --------------------------------- */}
        <FormControl style={{ width: 160 }} sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="era-select-helper-label">Product Era</InputLabel>
          <Select
            labelId="era-select-helper-label"
            id="era-select-helper"
            //value={props.productEraId + ""}
            value={props.admin ? props.productEraId + "" : productEraId}
            label="Product Era"
            onChange={handleEraChange}
          >
            <MenuItem
              value={"0"}
              onClick={(e) => {
                handleEraChange(e);
              }}
            >
              <em>No Filter</em>
            </MenuItem>
            {props.productEras.map((item) => {
              if (item.ProductEraId != null) {
                return (
                  <MenuItem
                    value={
                      props.filterRemoved === "productEraId"
                        ? "0"
                        : item.ProductEraId
                    }
                    key={
                      props.filterRemoved === "productEraId"
                        ? "0"
                        : item.ProductEraId
                    }
                    fullWidth
                  >
                    {item.EraDescription}
                  </MenuItem>
                );
              } else {
                return null;
              }
            })}
          </Select>
          <FormHelperText>Products by Era</FormHelperText>
        </FormControl>
        <FormControl style={{ width: 160 }} sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="discount-select-helper-label">Discount</InputLabel>
          <Select
            labelId="discount-select-helper-label"
            id="discount-select-helper"
            //value={props.discountId + ""}
            value={props.admin ? props.discountId + "" : discountId}
            label="Discount"
            onChange={handleDiscountChange}
          >
            <MenuItem
              value={"0"}
              onClick={(e) => {
                handleDiscountChange(e);
              }}
            >
              <em>No Filter</em>
            </MenuItem>
            {props.discounts.map((item) => {
              return (
                <MenuItem
                  value={
                    props.filterRemoved === "discountId" ? "0" : item.DiscountId
                  }
                  key={
                    props.filterRemoved === "discountId" ? "0" : item.DiscountId
                  }
                  fullWidth
                >
                  {item.Name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Products by Discount</FormHelperText>
        </FormControl>
      </div>
    </main>
  );
};

export default ShoppingOptions;
