import React from "react";
import {
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@material-ui/core";

import { Link } from "react-router-dom";

import { useConfirm } from "material-ui-confirm";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

//validation schema
let validationSchema = Yup.object().shape({
  colorName: Yup.string().required("Required"),
});

const ProductColorEdit = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const initVals = {
    colorId: props.selectedColor[0].ColorId,
    colorName: props.selectedColor[0].ColorName,
    isActive: props.selectedColor[0].IsActive,
  };

  const onSubmit = (values) => {
    confirm({ description: `The product color will be saved to the database.` })
      .then(() => {
        //post the values to api
        fetch(process.env.REACT_APP_API_PRODUCT_COLORS, {
          method: "PUT",
          body: JSON.stringify({ ...values }),
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data) => {
            props.setAction(props.actions.list);
            props.setRefreshColors(true);
          })
          .catch((err) => {
            console.log(err.message);
          });
      })
      .catch(() => console.log("Update cancelled."));
  };

  return (
    <div style={{ height: "100px" }}>
      <Card className={classes.padding}>
        <CardHeader
          titleTypographyProps={{
            fontSize: 20,
          }}
          subheaderTypographyProps={{
            fontSize: 10,
          }}
          title="Edit Product Color"
          subheader="September 14, 2016"
        ></CardHeader>
        <Formik
          initialValues={initVals}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ dirty, isValid, values, handleChange, handleBlur }) => {
            return (
              <Form>
                <CardContent>
                  <Grid container spacing={1} justify="center">
                    <Grid item xs={12} sm={6} md={6}>
                      <Field
                        label="Color Name"
                        variant="outlined"
                        fullWidth
                        name="colorName"
                        value={values.colorName}
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="select-outlined-label">
                          Is Active
                        </InputLabel>
                        <Select
                          labelId="select-outlined-label"
                          id="select-outlined"
                          label="Is Active"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.isActive}
                          name="isActive"
                        >
                          <MenuItem value="true">
                            <em>true</em>
                          </MenuItem>
                          <MenuItem value="false">
                            <em>false</em>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <CardActions>
                    <Button
                      component={Link}
                      variant="outlined"
                      to="/admin/shoppingoptions"
                      onClick={() => {
                        props.setAction(props.actions.list);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!dirty || !isValid}
                      variant="contained"
                      color="primary"
                      type="Submit"
                      className={classes.button}
                    >
                      Save
                    </Button>
                  </CardActions>
                </CardContent>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </div>
  );
};

export default ProductColorEdit;
