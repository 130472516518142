export const MenuItemsList = [
  {
    title: "Home",
    url: "/admin",
  },
  {
    title: "Product Types",
    url: "/admin/producttypes",
  },
  {
    title: "Product Categories",
    url: "/admin/categories",
  },
  {
    title: "Shopping Options",
    url: "/admin/shoppingoptions",
  },
  {
    title: "Products",
    url: "/admin/products",
    submenu: [
      {
        title: "Add Product",
        url: "/admin/products/add",
      },
      {
        title: "SKU Search",
        url: "/admin/products/skusearch",
      },
    ],
  },
];
