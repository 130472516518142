import React from "react";
import { Container, Typography, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import CartItem from "./CartItem/CartItem";
import useStyles from "./styles";

const Cart = ({
  cart,
  products,
  onUpdateCartQty,
  onRemoveFromCart,
  onEmptyCart,
}) => {
  const classes = useStyles();

  const handleEmptyCart = () => onEmptyCart();

  const renderEmptyCart = () => (
    <Typography variant="h6">
      You have no items in your shopping cart,&nbsp;
      <Link className={classes.link} to="/">
        start adding some
      </Link>
      .
    </Typography>
  );

  if (cart !== null && !cart.items) return "Loading";

  const renderCart = () => (
    <>
      <Grid container spacing={3}>
        {cart.items.map((lineItem) => (
          <Grid item xs={12} sm={4} key={lineItem.id}>
            <CartItem
              item={lineItem}
              products={products}
              onUpdateCartQty={onUpdateCartQty}
              onRemoveFromCart={onRemoveFromCart}
            />
          </Grid>
        ))}
      </Grid>
      <div className={classes.cardDetails}>
        <Typography variant="h5">Subtotal: ${cart.total}</Typography>
        <div>
          <Button
            className={classes.emptyButton}
            size="large"
            type="button"
            variant="contained"
            color="secondary"
            onClick={handleEmptyCart}
          >
            Empty cart
          </Button>
          <Button
            className={classes.checkoutButton}
            component={Link}
            to="/checkout"
            size="large"
            type="button"
            variant="contained"
            color="primary"
          >
            Checkout
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <Container>
      <Helmet>
        <title>Jewelry Shopping Cart</title>
        <meta name="description" content="Jewelry Shopping Cart" />
      </Helmet>
      <div className={classes.toolbar} />
      <Typography className={classes.title} variant="h5" gutterBottom>
        Your Shopping Cart
      </Typography>
      {cart
        ? !cart.items.length
          ? renderEmptyCart()
          : renderCart()
        : renderEmptyCart()}
    </Container>
  );
};

export default Cart;
