import React, { useState } from "react";

import { ConfirmProvider } from "material-ui-confirm";

import { Divider, Container } from "@material-ui/core";

import useStyles from "./styles";

import RingSizeAdd from "./RingSizeAdd";
import RingSizeEdit from "./RingSizeEdit";
import RingSizeList from "./RingSizeList";

const ShowRingSize = ({ ringSizes, setRefreshRingSizes }) => {
  const classes = useStyles();

  //used for choosing the right control for the action
  const actions = {
    list: "list",
    add: "add",
    update: "update",
  };

  const [action, setAction] = useState(actions.list);
  const [selectedSize, setSelectedSize] = useState();

  if (action === actions.list) {
    return (
      <Container justify="center">
        <div className={classes.toolbar} />
        <Divider></Divider>
        <p></p>
        <RingSizeList
          ringSizes={ringSizes}
          actions={actions}
          setAction={setAction}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
        ></RingSizeList>
      </Container>
    );
  } else if (action === actions.add) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <RingSizeAdd
            ringSizes={ringSizes}
            actions={actions}
            setAction={setAction}
            setRefreshRingSizes={setRefreshRingSizes}
          ></RingSizeAdd>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.update) {
    return (
      <Container justify="center">
        <div className={classes.toolbar} />
        <Divider></Divider>
        <ConfirmProvider>
          <RingSizeEdit
            ringSizes={ringSizes}
            actions={actions}
            setAction={setAction}
            setSelectedSize={setSelectedSize}
            selectedSize={selectedSize}
            setRefreshRingSizes={setRefreshRingSizes}
          ></RingSizeEdit>
        </ConfirmProvider>
      </Container>
    );
  }
};

export default ShowRingSize;
