import React, { useState } from "react";

import { ConfirmProvider } from "material-ui-confirm";

import { Divider, Container } from "@material-ui/core";

import useStyles from "./styles";

import ProductColorAdd from "./ProductColorAdd";
import ProductColorEdit from "./ProductColorEdit";
import ProductColorList from "./ProductColorList";

const ShowProductColors = ({ productColors, setRefreshColors }) => {
  const classes = useStyles();

  //used for choosing the right control for the action
  const actions = {
    list: "list",
    add: "add",
    update: "update",
  };

  const [action, setAction] = useState(actions.list);
  const [selectedColor, setSelectedColor] = useState();

  if (action === actions.list) {
    return (
      <Container justify="center">
        <div className={classes.toolbar} />
        <Divider></Divider>
        <p></p>
        <ProductColorList
          productColors={productColors}
          actions={actions}
          setAction={setAction}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
        ></ProductColorList>
      </Container>
    );
  } else if (action === actions.add) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <ProductColorAdd
            productColors={productColors}
            actions={actions}
            setAction={setAction}
            setRefreshColors={setRefreshColors}
          ></ProductColorAdd>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.update) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <ProductColorEdit
            productColors={productColors}
            actions={actions}
            setAction={setAction}
            setSelectedColor={setSelectedColor}
            selectedColor={selectedColor}
            setRefreshColors={setRefreshColors}
          ></ProductColorEdit>
        </Container>
      </ConfirmProvider>
    );
  }
};

export default ShowProductColors;
