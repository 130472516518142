import React, { useState } from "react";

import { ConfirmProvider } from "material-ui-confirm";

import { Divider, Container } from "@material-ui/core";

import useStyles from "./styles";

import DiscountAdd from "./DiscountAdd";
import DiscountEdit from "./DiscountEdit";
import DiscountList from "./DiscountList";

const ShowDiscount = ({ discounts, setRefreshDiscounts }) => {
  const classes = useStyles();

  //used for choosing the right control for the action
  const actions = {
    list: "list",
    add: "add",
    update: "update",
  };

  const [action, setAction] = useState(actions.list);
  const [selectedDiscount, setSelectedDiscount] = useState();

  if (action === actions.list) {
    return (
      <Container justify="center">
        <div className={classes.toolbar} />
        <Divider></Divider>
        <p></p>
        <DiscountList
          discounts={discounts}
          actions={actions}
          setAction={setAction}
          selectedDiscount={selectedDiscount}
          setSelectedDiscount={setSelectedDiscount}
        ></DiscountList>
      </Container>
    );
  } else if (action === actions.add) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <DiscountAdd
            discounts={discounts}
            actions={actions}
            setAction={setAction}
            setRefreshDiscounts={setRefreshDiscounts}
          ></DiscountAdd>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.update) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <DiscountEdit
            discounts={discounts}
            actions={actions}
            setAction={setAction}
            setSelectedDiscount={setSelectedDiscount}
            selectedDiscount={selectedDiscount}
            setRefreshDiscounts={setRefreshDiscounts}
          ></DiscountEdit>
        </Container>
      </ConfirmProvider>
    );
  }
};

export default ShowDiscount;
