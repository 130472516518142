import React from "react";

import Navbar from "./Navbar";
// ...
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header>
      <div className="nav-area">
        <Link to="/" className="logo">
          MF Public Site
        </Link>
        <div>
          <Navbar />
        </div>

        <p style={{ fontSize: "20px", color: "black", padding: "20px" }}>
          <a href={process.env.REACT_APP_API_EMAIL} target="_blank">
            Check Mail
          </a>
        </p>
      </div>
    </header>
  );
};

export default Header;
