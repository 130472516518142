import React, { useState } from "react";

import { ConfirmProvider } from "material-ui-confirm";

import { Divider, Container } from "@material-ui/core";

import useStyles from "./styles";

import ProductEraAdd from "./ProductEraAdd";
import ProductEraEdit from "./ProductEraEdit";
import ProductEraList from "./ProductEraList";

const ShowProductEra = ({ productEras, setRefreshProductEras }) => {
  const classes = useStyles();

  //used for choosing the right control for the action
  const actions = {
    list: "list",
    add: "add",
    update: "update",
  };

  const [action, setAction] = useState(actions.list);
  const [selectedEra, setSelectedEra] = useState();

  if (action === actions.list) {
    return (
      <Container justify="center">
        <div className={classes.toolbar} />
        <Divider></Divider>
        <p></p>
        <ProductEraList
          productEras={productEras}
          actions={actions}
          setAction={setAction}
          selectedEra={selectedEra}
          setSelectedEra={setSelectedEra}
        ></ProductEraList>
      </Container>
    );
  } else if (action === actions.add) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <ProductEraAdd
            productEras={productEras}
            actions={actions}
            setAction={setAction}
            setRefreshProductEras={setRefreshProductEras}
          ></ProductEraAdd>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.update) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <ProductEraEdit
            productEras={productEras}
            actions={actions}
            setAction={setAction}
            setSelectedEra={setSelectedEra}
            selectedEra={selectedEra}
            setRefreshProductEras={setRefreshProductEras}
          ></ProductEraEdit>
        </Container>
      </ConfirmProvider>
    );
  }
};

export default ShowProductEra;
