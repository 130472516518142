import React from "react";

import { Typography, Divider, Card, CardHeader, Grid } from "@material-ui/core";

import useStyles from "./styles";

const AdminHome = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      //style={{ minHeight: "10vh" }}
    >
      <Grid item xs={8}>
        <Card className={classes.padding}>
          <CardHeader title="Admin"></CardHeader>

          <div className={classes.toolbar} />
          <Divider></Divider>
          <Typography variant="h6" align="justify">
            Welcome to the administrative section of the website!
          </Typography>
          <p></p>
          <Typography variant="h6">
            Here you can add important information about your products, add,
            update or delete: categories, products with all usefull information
            that will be presented on the site, update product information and
            much more!
          </Typography>
          <p></p>
          <Typography variant="h6">
            You can also manage and review Orders information, User information,
            Contact information. Just click on the links above to access each
            specific section.
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AdminHome;
