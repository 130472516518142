import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

const SKUDialog = ({ products, setSelectedProduct }) => {
  const [open, setOpen] = useState(true);
  const [match, setMatch] = useState("none");
  const navigate = useNavigate();

  const handleSearch = () => {
    let filteredBySku = getSelectedProduct();
    if (filteredBySku !== null) {
      setSelectedProduct(filteredBySku);
      setOpen(false);
      navigate(`/admin/products/productdetails`);
    }
  };

  const handleClose = () => {
    setOpen(false);
    navigate(`/admin/products`);
  };

  const getSelectedProduct = () => {
    var selected = [];
    var sku = document.getElementById("sku").value;
    selected = products.filter((product) => product.SKU === sku);
    if (selected.length > 0) {
      setMatch("none");
      return selected[0];
    } else {
      setMatch("");
      return null;
    }
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Search for product by SKU code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the SKU code of the product you want to find.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="sku"
            label="SKU Code"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <Typography
            variant="body2"
            sx={{
              color: "red",
            }}
            display={match}
          >
            No product matches the sku.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSearch}>Search</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SKUDialog;
