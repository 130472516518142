import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CssBaseline, Paper, Typography } from "@material-ui/core";
import useStyles from "./styles";

const PaySuccess = ({ handleStripeSession }) => {
  const classes = useStyles();
  //session id returned from Stripe successful transaction
  let { session_id } = useParams();
  useEffect(() => {
    let cart = localStorage.getItem(process.env.REACT_APP_API_CART_CONTENT);
    handleStripeSession(session_id, cart);
  }, []);

  return (
    <>
      <CssBaseline />
      <div className={classes.toolbar} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h4" align="center">
            Thanks for your order!
          </Typography>
          <p></p>
          <p></p>
          <Typography variant="h5" align="center">
            Your payment is successful.
          </Typography>
          <Typography variant="h5" align="center">
            We appreciate your business!
          </Typography>
        </Paper>
      </main>
    </>
  );
};

export default PaySuccess;
