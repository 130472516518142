import React, { useState } from "react";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Stack from "@mui/material/Stack";
import { Helmet } from "react-helmet";

import Jeweler from "../../assets/AboutUs/jeweler.jpg";
import Shop_01 from "../../assets/AboutUs/shop-01.jpg";
import Shop_02 from "../../assets/AboutUs/shop-02.jpg";
import Shop_03 from "../../assets/AboutUs/shop-03.jpg";
import Shop_Address from "../../assets/AboutUs/shop-address.jpg";

import DropdownShareButton from "./DropdownShareButton";

import useStyles from "./styles";

const AboutUs = () => {
  const classes = useStyles();
  const shopImages = [Shop_01, Shop_02, Shop_03, Shop_Address];
  const [imgCount, setImgCount] = useState(0);

  const handlePhotoPrev = () => {
    if (imgCount > 0) {
      setImgCount(imgCount - 1);
    }
  };

  const handlePhotoNext = () => {
    if (imgCount < shopImages.length - 1) {
      setImgCount(imgCount + 1);
    }
  };

  return (
    <main className={classes.content}>
      <Helmet>
        <title>About Manuel Fridman's Jewelry</title>
        <meta
          name="description"
          content="About Manuel Fridman's Jewelry Bussiness"
        />
      </Helmet>
      <div className={classes.toolbar} />

      <Grid container>
        <Stack direction="row" spacing={2}>
          <Grid item xs={12} sm={5} md={5}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={Jeweler}
                  title="Manuel Fridman"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Manuel Fridman in his workshop
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    We believe in bringing the best quality, prices, and
                    services to our Canadian online community.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ justifyContent: "center" }}>
                <DropdownShareButton></DropdownShareButton>
              </CardActions>
            </Card>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={shopImages[imgCount]}
                  title="Manuel Fridman's Shop"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Jewelry Shop
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Our jewelry shop welcomes you any time.
                    <b>
                      F.M. Jewellers at 7 Pleasant Blvd., suite 202, Toronto,
                      Ontario, M4T 1K2.{" "}
                    </b>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ justifyContent: "center" }}>
                <Button size="small" color="primary" onClick={handlePhotoPrev}>
                  back
                </Button>
                <Button size="small" color="primary" onClick={handlePhotoNext}>
                  next
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <p></p>
            <Typography variant="h5" align="center">
              About F.M.Jewellers
            </Typography>
            <p></p>
            <Typography
              variant="h6"
              align="left"
              className={classes.typography}
            >
              My story began in 1982, as a young immigrant from Europe where I
              learned and mastered the jewelry trade. <p></p>Coming to Canada, I
              put roots down in Midtown Toronto and opened a jewelry
              studio/wholesale/manufacturing and repair shop that serves the
              community. <div></div>Today, the family-style business that my
              wife and I created has changed so much since those days. The
              internet has opened an exciting new world.
              <div></div>We believe in bringing the best quality, wholesale
              prices, and services to our Canadian online community.<div></div>
              To view our jewelry please visit our website:{" "}
              <b>https://fmjewellers.com</b>.<p></p>We still have a shop where
              our designs and manufacturing take place.
              <div></div>
              You are welcome to visit our shop by appointment only and bring
              your jewelry to be remodeled or repaired. Our services include
              expert custom jewelry repair, cleaning and polishing, restringing,
              special orders and watch battery replacement. All our pieces are
              carefully handmade, packaged, and shipped from our shop:{" "}
              <div></div>
              <b>
                F.M. Jewellers at 7 Pleasant Blvd., suite 202, Toronto, Ontario,
                M4T 1K2.
              </b>
              <div></div>You can reach us at
              <b> 416-932-9450</b> or email: <b>manuel@fmjewellers.com</b> to
              make an appointment if you are in Toronto and if you would like to
              stop in and see us. <p></p>Thank you for your continued support.
              <p></p>
              <b>Manuel Fridman</b>
            </Typography>
          </Grid>
        </Stack>
      </Grid>
    </main>
  );
};

export default AboutUs;
