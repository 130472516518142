import React, { useState } from "react";

import {
  Typography,
  Divider,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  FormControl,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import useStyles from "./styles";

const ProductMetalList = (props) => {
  const classes = useStyles();

  const [editEnabled, setEditEnabled] = useState(false);
  const [selectedMetalId, setSelectedMetalId] = useState(0);

  const handleMetalChange = (event) => {
    setEditEnabled(true);
    setSelectedMetalId(event.target.value);
  };

  const handleAddNew = (event) => {
    props.setAction(props.actions.add);
  };

  const handleEditMetal = (event) => {
    var selected = getSelectedMetal();
    props.setSelectedMetal(selected);
    props.setAction(props.actions.update);
  };

  const getSelectedMetal = () => {
    var selected;
    var id = getSelectedMetalId();
    selected = props.productMetals.filter(
      (selectedMetal) => selectedMetal.MetalId === id
    );
    return selected;
  };

  const getSelectedMetalId = () => {
    if (selectedMetalId == null) {
      return null;
    } else if (selectedMetalId != null) {
      return selectedMetalId;
    }
  };

  return (
    <Card className={classes.root}>
      {/* <CardHeader title="Product Metals"></CardHeader> */}
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <p></p>
          <Typography variant="h6">Product Metals</Typography>
          <Select
            width="150px;"
            variant="standard"
            autoWidth={true}
            labelId="metal-select-label"
            id="select-select"
            value={selectedMetalId}
            label="Product Metals"
            onChange={handleMetalChange}
          >
            <MenuItem disabled value="">
              <em>Please Select</em>
            </MenuItem>
            {props.productMetals.map((item) => {
              if (item.MetalId != null) {
                return (
                  <MenuItem value={item.MetalId} key={item.MetalId} fullWidth>
                    {item.Description}
                  </MenuItem>
                );
              } else {
                return null;
              }
            })}
          </Select>
        </FormControl>

        <Divider variant="middle"></Divider>
        <div style={{ height: "40px" }}></div>
        <Typography variant="h6">
          Add a new metal or edit a selected metal.
        </Typography>
        <p />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleAddNew}
          >
            Add New
          </Button>
          <Button
            disabled={!editEnabled}
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleEditMetal}
          >
            Edit Selected
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProductMetalList;
