import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";

const Review = ({ cart, checkedCart, onCaptureCheckout }) => {
  const [cartModified, setCartModified] = useState(false);

  const navigate = useNavigate();
  const goToCartPage = () => navigate("/cart");

  useEffect(() => {
    //check for items in cart. if something is wrong redirect to ./cart
    if (checkedCart.items === undefined) {
      goToCartPage();
    }
    //check products availability
    //if a product is no longer available or less than cart quantity
    //remove it from cart and set cartModified to true
    if (checkedCart && checkedCart.total_items < cart.total_items) {
      setCartModified(true);
    }

    //store cart data locally
    localStorage.setItem(
      process.env.REACT_APP_API_CART_CONTENT,
      JSON.stringify(checkedCart)
    );
  }, []);

  const handleSubmit = () => {
    onCaptureCheckout();
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {cart.items
          ? cart.items.map((product) =>
              !cartModified ? (
                <ListItem
                  style={{ padding: "10px 0" }}
                  key={product.cartItemId}
                >
                  <ListItemText
                    primary={product.productName}
                    secondary={`Quantity: ${product.quantity}`}
                  />
                  <Typography variant="body2">{product.total}</Typography>
                </ListItem>
              ) : (
                //check if product.cartItemId is in checkedCart.items
                //if it is not, show Item Unavailable

                <ListItem
                  style={{ padding: "10px 0" }}
                  key={product.cartItemId}
                >
                  {!checkedCart.items.find(
                    (obj) => obj.cartItemId === product.cartItemId
                  ) ? (
                    <>
                      <Typography variant="body2" style={{ color: "#ff6f00" }}>
                        Item Unavailable
                      </Typography>
                      <ListItemText
                        style={{ color: "#ff6f00" }}
                        primary={product.productName}
                        secondary={`Quantity: ${product.quantity}`}
                      />
                      <Typography variant="body2">{product.total}</Typography>
                    </>
                  ) : (
                    <>
                      <ListItemText
                        primary={product.productName}
                        secondary={`Quantity: ${product.quantity}`}
                      />
                      <Typography variant="body2">{product.total}</Typography>
                    </>
                  )}
                </ListItem>
              )
            )
          : ""}
        <ListItem style={{ padding: "10px 0" }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
            ${checkedCart ? checkedCart.total : ""}
          </Typography>
        </ListItem>
      </List>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            !(checkedCart.items !== undefined && checkedCart.items.length > 0)
          }
        >
          Proceed to payment
        </Button>
      </div>
    </>
  );
};

export default Review;
