import React from "react";

import { Grid } from "@material-ui/core";

import ShowProductColors from "./ProductColors/ShowProductColors";
import ShowProductMetal from "./ProductMetal/ShowProductMetal";
import ShowProductGemstone from "./ProductGemstone/ShowProductGemstone";
import ShowProductLength from "./ProductLength/ShowProductLength";
import ShowPearlType from "./PearlType/ShowPearlType";
import ShowPearlSize from "./PearlSize/ShowPearlSize";
import ShowRingSize from "./RingSize/ShowRingSize";
import ShowProductEras from "./ProductEra/ShowProductEras";
import ShowDiscounts from "./Discount/ShowDiscount";

const ShowShoppingOptions = (props) => {
  return (
    <div style={{ marginTop: 0 }}>
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ShowProductColors
            productColors={props.productColors}
            setRefreshColors={props.setRefreshColors}
          ></ShowProductColors>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ShowProductMetal
            productMetals={props.productMetals}
            setRefreshMetals={props.setRefreshMetals}
          ></ShowProductMetal>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ShowProductGemstone
            productGemstones={props.productGemstones}
            setRefreshGemstones={props.setRefreshGemstones}
          ></ShowProductGemstone>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ShowProductLength
            productLengths={props.productLengths}
            setRefreshLengths={props.setRefreshLengths}
          ></ShowProductLength>
        </Grid>
        {/*         <Grid item xs={12} sm={6} md={4} lg={3}>
          <ShowProductSizes
            productSizes={props.productSizes}
            setRefreshSizes={props.setRefreshSizes}
          ></ShowProductSizes>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ShowPearlType
            pearlTypes={props.pearlTypes}
            setRefreshPearlTypes={props.setRefreshPearlTypes}
          ></ShowPearlType>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ShowPearlSize
            pearlSizes={props.pearlSizes}
            setRefreshPearlSizes={props.setRefreshPearlSizes}
          ></ShowPearlSize>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ShowRingSize
            ringSizes={props.ringSizes}
            setRefreshRingSizes={props.setRefreshRingSizes}
          ></ShowRingSize>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ShowProductEras
            productEras={props.productEras}
            setRefreshProductEras={props.setRefreshProductEras}
          ></ShowProductEras>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ShowDiscounts
            discounts={props.discounts}
            setRefreshDiscounts={props.setRefreshDiscounts}
          ></ShowDiscounts>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowShoppingOptions;
