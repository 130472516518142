import React, { useState } from "react";

import { ConfirmProvider } from "material-ui-confirm";

import { Container } from "@material-ui/core";
import useStyles from "./styles";

import CategoryAdd from "./CategoryAdd";
import CategoryEdit from "./CategoryEdit";
import CategoryList from "./CategoryList";

const ShowCategories = (props) => {
  const classes = useStyles();

  const actions = {
    list: "list",
    add: "add",
    update: "update",
  };

  const [action, setAction] = useState("list");
  const [selectedCategory, setSelectedCategory] = useState();

  if (action === actions.list) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />

          <CategoryList
            productTypes={props.productTypes}
            categories={props.categories}
            actions={actions}
            setAction={setAction}
            setSelectedCategory={setSelectedCategory}
            productTypeId={props.productTypeId}
            setProductTypeId={props.setProductTypeId}
            setRefreshCategories={props.setRefreshCategories}
          ></CategoryList>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.add) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <CategoryAdd
            categories={props.categories}
            actions={actions}
            setAction={setAction}
            setRefreshCategories={props.setRefreshCategories}
            productTypeId={props.productTypeId}
          ></CategoryAdd>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.update) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          {/* <Divider></Divider> */}
          <CategoryEdit
            categories={props.categories}
            actions={actions}
            setAction={setAction}
            selectedCategory={selectedCategory}
            setRefreshCategories={props.setRefreshCategories}
            productTypeId={props.productTypeId}
          ></CategoryEdit>
        </Container>
      </ConfirmProvider>
    );
  }
};

export default ShowCategories;
