import React, { useState } from "react";

import { ConfirmProvider } from "material-ui-confirm";

import { Container } from "@material-ui/core";

import ProductTypeAdd from "./ProductTypeAdd";
import ProductTypeEdit from "./ProductTypeEdit";
import ProductTypeList from "./ProductTypeList";

const ShowProductTypes = ({ productTypes, setRefreshTypes }) => {
  const actions = {
    list: "list",
    add: "add",
    update: "update",
  };

  const [action, setAction] = useState(actions.list);
  const [selectedType, setSelectedType] = useState();

  if (action === actions.list) {
    return (
      <Container justify="center">
        <ProductTypeList
          productTypes={productTypes}
          actions={actions}
          setAction={setAction}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        ></ProductTypeList>
      </Container>
    );
  } else if (action === actions.add) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <ProductTypeAdd
            productTypes={productTypes}
            actions={actions}
            setAction={setAction}
            setRefreshTypes={setRefreshTypes}
          ></ProductTypeAdd>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.update) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <ProductTypeEdit
            productTypes={productTypes}
            actions={actions}
            setAction={setAction}
            setSelectedType={setSelectedType}
            selectedType={selectedType}
            setRefreshTypes={setRefreshTypes}
          ></ProductTypeEdit>
        </Container>
      </ConfirmProvider>
    );
  }
};

export default ShowProductTypes;
