import React, { useState } from "react";

import { ConfirmProvider } from "material-ui-confirm";

import { Divider, Container } from "@material-ui/core";

import useStyles from "./styles";

import PearlSizeAdd from "./PearlSizeAdd";
import PearlSizeEdit from "./PearlSizeEdit";
import PearlSizeList from "./PearlSizeList";

const ShowPearlSize = ({ pearlSizes, setRefreshPearlSizes }) => {
  const classes = useStyles();

  //used for choosing the right control for the action
  const actions = {
    list: "list",
    add: "add",
    update: "update",
  };

  const [action, setAction] = useState(actions.list);
  const [selectedSize, setSelectedSize] = useState();

  if (action === actions.list) {
    return (
      <Container justify="center">
        <div className={classes.toolbar} />
        <Divider></Divider>
        <p></p>
        <PearlSizeList
          pearlSizes={pearlSizes}
          actions={actions}
          setAction={setAction}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
        ></PearlSizeList>
      </Container>
    );
  } else if (action === actions.add) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <PearlSizeAdd
            pearlSizes={pearlSizes}
            actions={actions}
            setAction={setAction}
            setRefreshPearlSizes={setRefreshPearlSizes}
          ></PearlSizeAdd>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.update) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <PearlSizeEdit
            pearlSizes={pearlSizes}
            actions={actions}
            setAction={setAction}
            setSelectedSize={setSelectedSize}
            selectedSize={selectedSize}
            setRefreshPearlSizes={setRefreshPearlSizes}
          ></PearlSizeEdit>
        </Container>
      </ConfirmProvider>
    );
  }
};

export default ShowPearlSize;
