import React, { useState } from "react";

import {
  Grid,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  CardHeader,
  FormControl,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import useStyles from "./styles";

const ProductTypeList = (props) => {
  const classes = useStyles();

  const [editEnabled, setEditEnabled] = useState(false);
  const [selectedTypeId, setSelectedTypeId] = useState(0);

  const handleTypeChange = (event) => {
    setEditEnabled(true);
    setSelectedTypeId(event.target.value);
  };

  const getSelectedTypeId = () => {
    if (selectedTypeId == null) {
      return null;
    } else if (selectedTypeId != null) {
      return selectedTypeId;
    }
  };

  const getSelectedType = () => {
    var selected;
    var id = getSelectedTypeId();
    selected = props.productTypes.filter(
      (selectedType) => selectedType.ProductTypeId === id
    );
    return selected;
  };

  const handleAddNew = () => {
    props.setAction(props.actions.add);
  };

  const handleEditType = () => {
    var selected = getSelectedType();
    props.setSelectedType(selected);
    props.setAction(props.actions.update);
  };

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item md={6}>
        <Card className={classes.padding}>
          <CardHeader title="Product Types"></CardHeader>
          <CardContent>
            <Grid item container spacing={1} justify="center">
              <Grid item xs={12} sm={6} md={12}>
                <Grid container spacing={2}>
                  <Grid item spacing={4} zeroMinWidth>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <p></p>
                      <Typography variant="h6">Product Type List</Typography>
                      <Select
                        width="150px;"
                        variant="standard"
                        autoWidth={true}
                        labelId="type-select-label"
                        id="select-select"
                        value={selectedTypeId}
                        label="Product Type"
                        onChange={handleTypeChange}
                      >
                        <MenuItem disabled value="">
                          <em>Please Select</em>
                        </MenuItem>
                        {props.productTypes.map((item) => {
                          return (
                            <MenuItem
                              value={item.ProductTypeId}
                              key={item.ProductTypeId}
                              fullWidth
                            >
                              {item.Type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item spacing={4} zeroMinWidth justify="center">
                <div style={{ height: "40px" }}></div>
                <Typography variant="h6">
                  Add a new type or edit a selected type.
                </Typography>
                <p />
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    onClick={handleAddNew}
                  >
                    Add New
                  </Button>
                  <Button
                    disabled={!editEnabled}
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    onClick={handleEditType}
                  >
                    Edit Selected
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProductTypeList;
