import React, { useState } from "react";

import {
  Typography,
  Divider,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  FormControl,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import useStyles from "./styles";

const PearlTypeList = (props) => {
  const classes = useStyles();

  const [editEnabled, setEditEnabled] = useState(false);
  const [selectedTypeId, setSelectedTypeId] = useState(0);

  const handleTypeChange = (event) => {
    setEditEnabled(true);
    setSelectedTypeId(event.target.value);
  };

  const handleAddNew = (event) => {
    props.setAction(props.actions.add);
  };

  const handleEditType = (event) => {
    var selected = getSelectedType();
    props.setSelectedType(selected);
    props.setAction(props.actions.update);
  };

  const getSelectedType = () => {
    var selected;
    var id = getSelectedTypeId();
    selected = props.pearlTypes.filter(
      (selectedType) => selectedType.TypeId === id
    );
    return selected;
  };

  const getSelectedTypeId = () => {
    if (selectedTypeId == null) {
      return null;
    } else if (selectedTypeId != null) {
      return selectedTypeId;
    }
  };

  return (
    <Card className={classes.root}>
      {/* <CardHeader title="Product Colors"></CardHeader> */}
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <p></p>
          <Typography variant="h6">Pearl Types</Typography>
          <Select
            width="150px;"
            variant="standard"
            autoWidth={true}
            labelId="type-select-label"
            id="select-select"
            value={selectedTypeId}
            label="Pearl Types"
            onChange={handleTypeChange}
          >
            <MenuItem disabled value="">
              <em>Please Select</em>
            </MenuItem>
            {props.pearlTypes.map((item) => {
              if (item.TypeId != null) {
                return (
                  <MenuItem value={item.TypeId} key={item.TypeId} fullWidth>
                    {item.Description}
                  </MenuItem>
                );
              } else {
                return null;
              }
            })}
          </Select>
        </FormControl>

        <Divider variant="middle"></Divider>
        <div style={{ height: "40px" }}></div>
        <Typography variant="h6">
          Add a new pearl type or edit a selected type.
        </Typography>
        <p />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleAddNew}
          >
            Add New
          </Button>
          <Button
            disabled={!editEnabled}
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleEditType}
          >
            Edit Selected
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PearlTypeList;
