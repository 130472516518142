import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    maxWidth: 345, //original width style
    margin: "0 auto",
    padding: "0.1em",
    display: "block",
    width: "45vw",
    transition: "transform 0.15s ease-in-out",
    transitionDuration: "0.3s",
    height: "55vw",
  },
  root_details: {
    mt: "10%",
    maxWidth: 1000,
    maxHeight: 400,
    margin: "0 auto",
    padding: "0.1em",
    display: "block",
    width: "45vw",
    transition: "transform 0.15s ease-in-out",
    transitionDuration: "0.3s",
    height: "35vw",
  },
  card_small: {
    maxWidth: 345,
    maxHeight: 400,
    margin: "0 auto",
    padding: "0.1em",
    display: "block",
    width: "45vw",
    transition: "transform 0.15s ease-in-out",
    transitionDuration: "0.3s",
    height: "35vw",
  },
  grid_container: {
    display: "flex",
  },
  grid_item: {
    backgroundColor: "#e91e63",
  },
  card: {
    maxWidth: 200,
  },
  media_details: {
    height: 400,
    width: "50%",
    marginLeft: "5%",
    padding: "1em 1em 1em 1em",
    objectFit: "contain",
  },
  media_details_small: {
    height: 400,
    width: "100%",
    marginLeft: "5%",
    padding: "1em 1em 1em 1em",
    objectFit: "contain",
  },
  toolbar: theme.mixins.toolbar,
  title: {
    marginTop: "5%",
  },
  media: {
    height: "50%",
    paddingTop: "56.25%", // 16:9
    width: "100%",
    marginLeft: "5%",
    padding: "1em 1em 1em 1em",
    objectFit: "contain",
  },
  cardDetails: {
    display: "flex",
    marginTop: "10%",
    width: "50%",
    justifyContent: "space-between",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cardContent: {
    display: "flex",

    justifyContent: "space-between",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
