import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  GridList,
  GridListTile,
  Divider,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

//----------------------------------
/* import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers"; */
//----------------------------------

import useStyles from "../Product/styles";
import { Helmet } from "react-helmet";

function PubProductDetails(props) {
  const [product, setProduct] = useState(props.selectedProduct);
  const [ringSizeRequired, setRingSizeRequired] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    setProduct(props.selectedProduct);
    if (product.Category !== undefined) {
      setRingSizeRequired(product.Category.includes("Rings"));
    } else {
      navigate("/");
    }
  }, [product]);

  var randomColor = require("randomcolor");
  var CurrencyFormat = require("react-currency-format");

  let imageUrl = process.env.REACT_APP_API_HOST + product.PrimaryImageUrl;
  let imageUrlSc = process.env.REACT_APP_API_HOST + product.SecondaryImageUrl;
  //--------- product options -----------------

  let lengthDescriptionShow = product.LengthDescription ? true : false;
  let pearlTypeShow = product.PearlType ? true : false;
  let pearlSizeShow = product.PearlSize ? true : false;
  let sizeShow = product.Size ? true : false;
  let commentsShow = product.Comments ? true : false;
  let eraShow = product.EraDescription ? true : false;
  let designerShow = product.Designer ? true : false;
  let metalShow = product.MetalDescription ? true : false;
  let gemstoneShow = product.GemstoneName ? true : false;

  const handleAskForProduct = () => {
    navigate("/cart/AskForProduct");
  };

  const handleAddToCart = () => {
    props.handleAddToCart(product.ProductId, 1);
    navigate("/cart");
  };

  const handleViewCart = () => {
    navigate("/cart");
  };

  return (
    <main className={classes.content}>
      <Helmet>
        <title>{product.ProductName}</title>
        <meta name="description" content={product.Description} />
      </Helmet>
      <div className={classes.toolbar} />
      <Grid
        container
        spacing={2}
        margin={2}
        justify="center"
        className="grid_container"
        //style={{ background: randomColor() }}
      >
        <Grid
          item
          xs={6}
          className="grid_item"
          //style={{ background: randomColor() }}
        >
          <Card className={classes.root_details}>
            <CardMedia
              className={classes.media_details}
              image={imageUrl}
              title={product.ProductName}
              xs={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
            />
            <CardContent></CardContent>
          </Card>
          <GridList cellHeight={600} className={classes.gridList} cols={2}>
            <GridListTile key={1}>
              <Card className={classes.card_small}>
                <CardMedia
                  className={classes.media_details_small}
                  image={imageUrl}
                  title={product.ProductName}
                  xs={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />
                <CardContent></CardContent>
              </Card>
            </GridListTile>
            <GridListTile key={2}>
              <Card className={classes.card_small}>
                <CardMedia
                  className={classes.media_details_small}
                  image={imageUrlSc}
                  title={product.ProductName}
                  xs={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />
                <CardContent></CardContent>
              </Card>
            </GridListTile>
          </GridList>
        </Grid>
        <Grid
          item
          xs={6}
          className="grid_item"
          //style={{ background: randomColor() }}
        >
          <Box height="55vh" display="flex" flexDirection="column">
            <div
              style={{
                background: "#eee",
                padding: "10px",
                margin: "10px",
              }}
            >
              <Typography variant="h6">
                Free shipping available in Canada
              </Typography>
            </div>
            <div className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                {product.ProductName}
              </Typography>
            </div>

            <div>
              <Typography
                dangerouslySetInnerHTML={{ __html: product.Description }}
                variant="h6"
                color="textSecondary"
                component="p"
              />
            </div>
            <Divider></Divider>
            <div
              style={{
                background: "#eee",
                padding: "20px",
                margin: "20px",
              }}
            >
              <div
                hidden={!ringSizeRequired}
                style={{
                  background: "#eee",
                  padding: "2px",
                  margin: "10px",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Typography variant="h6">Select Ring Size</Typography>
                  <FormControl xs={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                      width="150px;"
                      variant="standard"
                      autoWidth={true}
                      labelId="ringSize-select-label"
                      id="ringSize-select"
                      value={product.ringSizeId}
                      label="Ring Size"
                      //onChange={handleRingSizeChange}
                    >
                      <MenuItem disabled value="">
                        <em>Please Select</em>
                      </MenuItem>
                      {props.ringSizes.map((item) => {
                        return (
                          <MenuItem
                            value={item.RingSizeId}
                            key={item.RingSizeId}
                            fullWidth
                          >
                            {item.RingSize}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Typography variant="h6" color="textPrimary">
                    Required
                  </Typography>
                </Stack>
              </div>
              <Grid container cols={12}>
                <Grid item xs={12} sm={4}>
                  <Typography gutterBottom variant="h5" component="h2">
                    <CurrencyFormat
                      value={product.Price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$  "}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography gutterBottom variant="h5" component="h2">
                    <Button
                      variant="contained"
                      color="success"
                      fullWidth="true"
                      onClick={handleAskForProduct}
                    >
                      REQUEST BY EMAIL
                    </Button>
                  </Typography>
                  {/*                 {props.loggedIn ? (
                  <div>
                    <Typography gutterBottom variant="h5" component="h2">
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth="true"
                        onClick={handleAddToCart}
                      >
                        ADD TO CART
                      </Button>
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2">
                      <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth="true"
                        onClick={handleViewCart}
                      >
                        VIEW CART
                      </Button>
                    </Typography>
                  </div>
                ) : (
                  <div></div>
                )} */}
                  <div>
                    <Typography gutterBottom variant="h5" component="h2">
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth="true"
                        onClick={handleAddToCart}
                      >
                        ADD TO CART
                      </Button>
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2">
                      <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth="true"
                        onClick={handleViewCart}
                      >
                        VIEW CART
                      </Button>
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} alignItems="flex-end">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    align="right"
                  >
                    IN STOCK
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Divider></Divider>
            <div className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                Product Details
              </Typography>
            </div>
            <Divider></Divider>
            <div>
              <Typography variant="body1">SKU: {product.SKU}</Typography>
              <Divider></Divider>
              <div hidden={!designerShow}>
                <Typography variant="body1">
                  DESIGNER: {product.Designer}
                </Typography>
                <Divider></Divider>
              </div>
              <div hidden={!metalShow}>
                <Typography variant="body1">
                  METAL: {product.MetalDescription}
                </Typography>
                <Divider></Divider>
              </div>
              <div hidden={!gemstoneShow}>
                <Typography variant="body1">
                  GEMSTONE: {product.GemstoneName}
                </Typography>
                <Divider></Divider>
              </div>
              <div hidden={!sizeShow}>
                <Typography variant="body1">SIZE: {product.Size}</Typography>
                <Divider></Divider>
              </div>
              <div hidden={!lengthDescriptionShow}>
                <Typography variant="body1">
                  LENGTH: {product.LengthDescription}
                </Typography>
              </div>
              <div hidden={!pearlTypeShow}>
                <Divider></Divider>
                <Typography variant="body1">
                  PEARL TYPE: {product.PearlType}
                </Typography>
              </div>
              <div hidden={!pearlSizeShow}>
                <Divider></Divider>
                <Typography variant="body1">
                  PEARL SIZE: {product.PearlSize}
                </Typography>
              </div>
              <div hidden={!eraShow}>
                <Divider></Divider>
                <Typography variant="body1">
                  PRODUCT ERA: {product.EraDescription}
                </Typography>
              </div>
              <div hidden={!commentsShow}>
                <Divider></Divider>
                <Typography variant="body1">
                  PRODUCT COMMENTS: {product.Comments}
                </Typography>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </main>
  );
}

export default PubProductDetails;
