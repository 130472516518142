import React, { useState } from "react";

import { ConfirmProvider } from "material-ui-confirm";

import { Divider, Container } from "@material-ui/core";

import useStyles from "./styles";

import ProductLengthAdd from "./ProductLengthAdd";
import ProductLengthEdit from "./ProductLengthEdit";
import ProductLengthList from "./ProductLengthList";

const ShowProductLength = ({ productLengths, setRefreshLengths }) => {
  const classes = useStyles();

  //used for choosing the right control for the action
  const actions = {
    list: "list",
    add: "add",
    update: "update",
  };

  const [action, setAction] = useState(actions.list);
  const [selectedLength, setSelectedLength] = useState();

  if (action === actions.list) {
    return (
      <Container justify="center">
        <div className={classes.toolbar} />
        <Divider></Divider>
        <p></p>
        <ProductLengthList
          productLengths={productLengths}
          actions={actions}
          setAction={setAction}
          selectedLength={selectedLength}
          setSelectedLength={setSelectedLength}
        ></ProductLengthList>
      </Container>
    );
  } else if (action === actions.add) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <ProductLengthAdd
            productLengths={productLengths}
            actions={actions}
            setAction={setAction}
            setRefreshLengths={setRefreshLengths}
          ></ProductLengthAdd>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.update) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <ProductLengthEdit
            productLengths={productLengths}
            actions={actions}
            setAction={setAction}
            setSelectedLength={setSelectedLength}
            selectedLength={selectedLength}
            setRefreshLengths={setRefreshLengths}
          ></ProductLengthEdit>
        </Container>
      </ConfirmProvider>
    );
  }
};

export default ShowProductLength;
