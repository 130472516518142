import React, { useState } from "react";

import {
  Typography,
  Divider,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  FormControl,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import useStyles from "./styles";

const RingSizeList = (props) => {
  const classes = useStyles();

  const [editEnabled, setEditEnabled] = useState(false);
  const [selectedSizeId, setSelectedSizeId] = useState(0);

  const handleSizeChange = (event) => {
    setEditEnabled(true);
    setSelectedSizeId(event.target.value);
  };

  const handleAddNew = (event) => {
    props.setAction(props.actions.add);
  };

  const handleEditSize = (event) => {
    var selected = getSelectedSize();
    props.setSelectedSize(selected);
    props.setAction(props.actions.update);
  };

  const getSelectedSize = () => {
    var selected;
    var id = getSelectedSizeId();
    selected = props.ringSizes.filter(
      (selectedSize) => selectedSize.RingSizeId === id
    );
    return selected;
  };

  const getSelectedSizeId = () => {
    if (selectedSizeId == null) {
      return null;
    } else if (selectedSizeId != null) {
      return selectedSizeId;
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <p></p>
          <Typography variant="h6">Ring Sizes</Typography>
          <Select
            width="150px;"
            variant="standard"
            autoWidth={true}
            labelId="size-select-label"
            id="select-select"
            value={selectedSizeId}
            label="Ring Size"
            onChange={handleSizeChange}
          >
            <MenuItem disabled value="">
              <em>Please Select</em>
            </MenuItem>
            {props.ringSizes.map((item) => {
              if (item.RingSizeId != null) {
                return (
                  <MenuItem
                    value={item.RingSizeId}
                    key={item.RingSizeId}
                    fullWidth
                  >
                    {item.RingSize}
                  </MenuItem>
                );
              } else {
                return null;
              }
            })}
          </Select>
        </FormControl>

        <Divider variant="middle"></Divider>
        <div style={{ height: "40px" }}></div>
        <Typography variant="h6">
          Add a new size or edit a selected size.
        </Typography>
        <p />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleAddNew}
          >
            Add New
          </Button>
          <Button
            disabled={!editEnabled}
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleEditSize}
          >
            Edit Selected
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default RingSizeList;
