import * as React from "react";
import Container from "@mui/material/Container";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const SiteFooter = () => {
  return (
    <Paper
      //sx={{ marginTop: "calc(10% + 2px)", bottom: 0 }}
      component="footer"
      square
      variant="outlined"
    >
      <Container maxWidth="lg">
        {/*         <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            my: 1,
          }}
        ></Box> */}

        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "left",
            display: "flex",
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="body1" color="initial">
            ©2023 Dacris Software Inc. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export default SiteFooter;
