import Shop01 from "../../assets/AboutUs/shop01.png";
import Shop02 from "../../assets/AboutUs/shop02.png";
import Shop03 from "../../assets/AboutUs/shop03.png";

/* */

export const sliderData = [
  {
    id: 1,
    image: Shop01,
    heading: "Best quality, wholesale prices, and services.",
    desc: "We believe in bringing the best quality, wholesale prices, and services to our Canadian online community.",
  },
  {
    id: 2,
    image: Shop02,
    heading: "Free shipping anywhere in Canada.",
    desc: "All our pieces are carefully handmade, packaged, and free shipped from our shop anywhere in Canada.",
  },
  {
    id: 3,
    image: Shop03,
    heading: "Excellent services any time.",
    desc: "Our services include expert custom jewelry repair, cleaning and polishing, restringing, special orders and watch battery replacement.",
  },
];
