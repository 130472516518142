import React from "react";
import {
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@material-ui/core";

import { useConfirm } from "material-ui-confirm";

import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const initialValues = {
  description: "",
  isActive: "true",
};

//validation schema
let validationSchema = Yup.object().shape({
  description: Yup.string().required("Required"),
});

const ProductLengthAdd = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const onSubmit = async (values) => {
    //console.log(values);

    confirm({
      description: `The new product length will be saved to the database.`,
    })
      .then(() => {
        //alert(JSON.stringify(values, null, 2));
        //post the values to api
        fetch(process.env.REACT_APP_API_PRODUCT_LENGTHS, {
          method: "POST",
          body: JSON.stringify({ ...values }),
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data) => {
            props.setAction(props.actions.list);
            props.setRefreshLengths(true);
          })
          .catch((err) => {
            console.log(err.message);
            props.setAction(props.actions.list);
          });
      })
      .catch(() => console.log("Action cancelled."));
  };

  return (
    <Card className={classes.padding}>
      <CardHeader title="Add Product Length"></CardHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid, values, handleChange, handleBlur }) => {
          return (
            <Form>
              <CardContent>
                <Grid container spacing={1} justify="center">
                  <Grid item xs={12} sm={6} md={6}>
                    <Field
                      label="Product Length"
                      variant="outlined"
                      fullWidth
                      name="description"
                      value={values.description}
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="select-outlined-label">
                        Is Active
                      </InputLabel>
                      <Select
                        labelId="select-outlined-label"
                        id="select-outlined"
                        label="Is Active"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.isActive}
                        name="isActive"
                      >
                        <MenuItem value="true">
                          <em>true</em>
                        </MenuItem>
                        <MenuItem value="false">
                          <em>false</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* //// */}
                <CardActions>
                  <Button
                    component={Link}
                    variant="outlined"
                    to="/admin/shoppingoptions"
                    onClick={() => {
                      props.setAction(props.actions.list);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!dirty || !isValid}
                    variant="contained"
                    color="primary"
                    type="Submit"
                    className={classes.button}
                  >
                    Save
                  </Button>
                </CardActions>
              </CardContent>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default ProductLengthAdd;
