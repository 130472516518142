import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Navbar,
  Products,
  Cart,
  Checkout,
  PaySuccess,
  PayCancel,
  PubProductDetails,
  SiteFooter,
} from "./components";
import { ConfirmProvider } from "material-ui-confirm";

import {
  LoginForm,
  AdminHome,
  ShowCategories,
  ShowProductTypes,
  ShowProducts,
  ShowShoppingOptions,
  ProductAdd,
  ProductWithUploader,
  ProductDetails,
  ProductEdit,
} from "./admin/components";

import Layout from "./admin/components/Navigation/Layout";

import { commerce } from "./lib/commerce";
import AskForProduct from "./components/Contact/AskProduct";
import SKUDialog from "./admin/components/SearchBySKU/SKUDialog";
import AboutUs from "./components/Contact/AboutUs";

const App = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [cart, setCart] = useState({});
  const [checkedCart, setCheckedCart] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const [loggedIn, setLoggedIn] = useState(false);

  //---------------------------------------
  const [categories, setCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  //used to filter categories based on prod type
  const [productTypeId, setProductTypeId] = useState(
    process.env.REACT_APP_PRODUCT_TYPE_ID_MAIN
  );
  const [productColors, setProductColors] = useState([]);
  const [productMetals, setProductMetals] = useState([]);
  const [productGemstones, setProductGemstones] = useState([]);
  const [productLengths, setProductLengths] = useState([]);
  const [pearlTypes, setPearlTypes] = useState([]);
  const [pearlSizes, setPearlSizes] = useState([]);
  const [ringSizes, setRingSizes] = useState([]);
  const [productEras, setProductEras] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [newProductId, setNewProductId] = useState("");
  const [imageType, setImageType] = useState("primary");
  //--------------Force Refresh All------------------------
  const [refreshProducts, setRefreshProducts] = useState(false);
  const [refreshColors, setRefreshColors] = useState(false);
  const [refreshCategories, setRefreshCategories] = useState(false);
  const [refreshTypes, setRefreshTypes] = useState(false);
  const [refreshMetals, setRefreshMetals] = useState(false);
  const [refreshGemstones, setRefreshGemstones] = useState(false);
  const [refreshLengths, setRefreshLengths] = useState(false);
  const [refreshPearlTypes, setRefreshPearlTypes] = useState(false);
  const [refreshPearlSizes, setRefreshPearlSizes] = useState(false);
  const [refreshRingSizes, setRefreshRingSizes] = useState(false);
  const [refreshProductEras, setRefreshProductEras] = useState(false);
  const [refreshDiscounts, setRefreshDiscounts] = useState(false);
  //-----------------USEEFECT------------------
  useEffect(() => {
    fetchProducts();
    fetchCart();

    //-----------------------------------------
    //product types
    loadProductTypes();
    //product categories
    loadProductCategories();
    //product colors
    loadColors();
    //product metals
    loadProductMetals();
    //product gemstones
    loadProductGemstones();
    //product lengths
    loadProductLengths();
    //pearl types
    loadPearlTypes();
    //pearl sizes
    loadPearlSizes();
    //ring sizes
    loadRingSizes();
    //product eras
    loadProductEras();
    //discounts
    loadDiscounts();
  }, [loggedIn]);

  useEffect(() => {
    filterOptions();
  }, [products]);

  //--------------------Hide banner when scrolling---------------
  const [position, setPosition] = useState(window.pageYOffset);
  const [sliderVisible, setSliderVisible] = useState(true);
  //turn off the slider when user clicks Get Started btn
  const [sliderOff, setSliderOff] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.scrollY;

      if (!sliderOff) {
        setSliderVisible(position > moving);
        setPosition(moving);
      } else {
        setSliderVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  //-------------------------------------------------------------

  const fetchProducts = async () => {
    fetch(process.env.REACT_APP_API_PRODUCT_LIST)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //----------------------------------------------
  const filterOptions = async () => {
    const filteredColors = productColors.filter((el) => {
      return products.some((f) => {
        return f.ColorId === el.ColorId && el.IsActive === true;
      });
    });
    const filteredMetals = productMetals.filter((el) => {
      return products.some((f) => {
        return f.MetalId === el.MetalId && el.IsActive === true;
      });
    });
    const filteredGemstones = productGemstones.filter((el) => {
      return products.some((f) => {
        return f.GemstoneId === el.GemstoneId && el.IsActive === true;
      });
    });
    const filteredPearlTypes = pearlTypes.filter((el) => {
      return products.some((f) => {
        return f.PearlTypeId === el.TypeId && el.IsActive === true;
      });
    });
    const filteredPearlSizes = pearlSizes.filter((el) => {
      return products.some((f) => {
        return f.PearlSizeId === el.PearlSizeId && el.IsActive === true;
      });
    });
    const filteredLengths = productLengths.filter((el) => {
      return products.some((f) => {
        return f.LengthId === el.LengthId && el.IsActive === true;
      });
    });
    const filteredEras = productEras.filter((el) => {
      return products.some((f) => {
        return f.ProductEraId === el.ProductEraId && el.IsActive === true;
      });
    });
    const filteredDiscounts = discounts.filter((el) => {
      return products.some((f) => {
        return f.DiscountId === el.DiscountId && el.IsActive === true;
      });
    });

    if (!loggedIn) {
      if (filteredColors.length > 0) {
        setProductColors(filteredColors);
      }
      if (filteredMetals.length > 0) {
        setProductMetals(filteredMetals);
      }
      if (filteredGemstones.length > 0) {
        setProductGemstones(filteredGemstones);
      }
      if (filteredPearlTypes.length > 0) {
        setPearlTypes(filteredPearlTypes);
      }
      if (filteredPearlSizes.length > 0) {
        setPearlSizes(filteredPearlSizes);
      }
      if (filteredLengths.length > 0) {
        setProductLengths(filteredLengths);
      }
      if (filteredEras.length > 0) {
        setProductEras(filteredEras);
      }
      if (filteredDiscounts.length > 0) {
        setDiscounts(filteredDiscounts);
      }
    }
  };
  //----------------------------------------------

  const fetchCart = async () => {
    setCart(await commerce.cart.retrieve());
  };

  const handleAddToCart = async (productId, quantity) => {
    setCart(await commerce.cart.add(productId, quantity));
  };

  const handleUpdateCartQty = async (lineItemId, quantity) => {
    setCart(await commerce.cart.update(lineItemId, quantity));
  };

  const handleRemoveFromCart = async (lineItemId) => {
    setCart(await commerce.cart.update(lineItemId, 0));
  };

  const handleEmptyCart = async () => {
    setCart((await commerce.cart.empty()) ? commerce.cart : null);
  };

  const refreshCart = async () => {
    const newCart = await commerce.cart.refresh();

    setCart(newCart);
  };

  const handleCaptureCheckout = async () => {
    try {
      const incomingOrder = await commerce.checkout.makePayment(checkedCart);
      //console.log(JSON.stringify(incomingOrder));
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const handleStripeSession = async (session_id, cart) => {
    try {
      //remove cart token
      localStorage.removeItem(process.env.REACT_APP_API_CART_KEY);
      localStorage.removeItem(process.env.REACT_APP_API_CART_CONTENT);
      setCart(null);
      //post the values to api
      let jsonCart = JSON.stringify(cart);
      let cartWithSession = { session_id: session_id, cart: cart };

      await fetch(process.env.REACT_APP_API_STRIPE_SUCCESS, {
        method: "POST",
        body: JSON.stringify(cartWithSession),
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {
      setErrorMessage(error);
    }
  };

  /*   useEffect(() => {
    fetchProducts();
    fetchCart();

    //-----------------------------------------
    //product types
    loadProductTypes();
    //product categories
    loadProductCategories();
    //product colors
    loadColors();
    //product metals
    loadProductMetals();
    //product gemstones
    loadProductGemstones();
    //product lengths
    loadProductLengths();
    //pearl types
    loadPearlTypes();
    //pearl sizes
    loadPearlSizes();
    //ring sizes
    loadRingSizes();
    //product eras
    loadProductEras();
    //discounts
    loadDiscounts();
  }, [loggedIn]);

  useEffect(() => {
    filterOptions();
  }, [products]); */

  //---------------------------------------------------------
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  //load product types when needed
  const loadProductTypes = () => {
    fetch(process.env.REACT_APP_API_PRODUCT_TYPES)
      .then((response) => response.json())
      .then((data) => {
        setProductTypes(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //load product categories when needed
  const loadProductCategories = () => {
    fetch(process.env.REACT_APP_API_PRODUCT_CATEGORIES)
      .then((response) => response.json())
      .then((data) => {
        //filter by productTypeId
        var categoriesByProductType = data.filter(
          (category) => category.ProductTypeId == productTypeId
        );

        setCategories(categoriesByProductType);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //load product colors when needed
  const loadColors = () => {
    fetch(process.env.REACT_APP_API_PRODUCT_COLORS)
      .then((response) => response.json())
      .then((data) => {
        setProductColors(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //load product metals when needed
  const loadProductMetals = () => {
    fetch(process.env.REACT_APP_API_PRODUCT_METALS)
      .then((response) => response.json())
      .then((data) => {
        setProductMetals(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //load product gemstones when needed
  const loadProductGemstones = () => {
    fetch(process.env.REACT_APP_API_PRODUCT_GEMSTONES)
      .then((response) => response.json())
      .then((data) => {
        setProductGemstones(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //load product lengths when needed
  const loadProductLengths = () => {
    fetch(process.env.REACT_APP_API_PRODUCT_LENGTHS)
      .then((response) => response.json())
      .then((data) => {
        setProductLengths(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //load pearl types when needed
  const loadPearlTypes = () => {
    fetch(process.env.REACT_APP_API_PEARL_TYPES)
      .then((response) => response.json())
      .then((data) => {
        setPearlTypes(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //load pearl sizes when needed
  const loadPearlSizes = () => {
    fetch(process.env.REACT_APP_API_PEARL_SIZES)
      .then((response) => response.json())
      .then((data) => {
        setPearlSizes(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //load ring sizes when needed
  const loadRingSizes = () => {
    fetch(process.env.REACT_APP_API_RING_SIZES)
      .then((response) => response.json())
      .then((data) => {
        setRingSizes(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //load product eras when needed
  const loadProductEras = () => {
    fetch(process.env.REACT_APP_API_PRODUCT_ERAS)
      .then((response) => response.json())
      .then((data) => {
        setProductEras(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //load discounts when needed
  const loadDiscounts = () => {
    fetch(process.env.REACT_APP_API_DISCOUNTS)
      .then((response) => response.json())
      .then((data) => {
        setDiscounts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  //------------products
  if (refreshProducts === true) {
    fetchProducts();
    setRefreshProducts(false);
  }
  //------------product types
  if (refreshTypes === true) {
    loadProductTypes();
    setRefreshTypes(false);
  }
  //------------product categories
  if (refreshCategories === true) {
    loadProductCategories();
    setRefreshCategories(false);
  }
  //------------shopping options
  if (refreshColors === true) {
    loadColors();
    setRefreshColors(false);
  }
  if (refreshMetals === true) {
    loadProductMetals();
    setRefreshMetals(false);
  }
  if (refreshGemstones === true) {
    loadProductGemstones();
    setRefreshGemstones(false);
  }
  if (refreshLengths === true) {
    loadProductLengths();
    setRefreshLengths(false);
  }
  if (refreshPearlTypes === true) {
    loadPearlTypes();
    setRefreshPearlTypes(false);
  }
  if (refreshPearlSizes === true) {
    loadPearlSizes();
    setRefreshPearlSizes(false);
  }
  if (refreshRingSizes === true) {
    loadRingSizes();
    setRefreshRingSizes(false);
  }
  if (refreshProductEras === true) {
    loadProductEras();
    setRefreshProductEras(false);
  }
  if (refreshDiscounts === true) {
    loadDiscounts();
    setRefreshDiscounts(false);
  }

  return (
    <>
      {" "}
      <Helmet>
        <title>Manuel Fridman's Jewelry Shop</title>
        <meta
          name="description"
          content="Best jewelry quality, wholesale prices and services."
        />
        <meta
          name="keywords"
          content="jewelry, wholesale jewelry, jewelry repair, best quality jewelry, in house jewelry design, best jewelry prices, free jewelry shipment, toronto jewellers, canadian jewellers, afordable jewelry, jewelry for any occasion"
        />
      </Helmet>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div style={{ padding: "1rem" }}>
              <Navbar
                totalItems={cart ? cart.total_items : 0}
                handleDrawerToggle={handleDrawerToggle}
                sliderVisible={sliderVisible}
                //used for the Get Started button
                setSliderVisible={setSliderVisible}
                //usex to turn off the Slider when GetStarted btn clicked
                setSliderOff={setSliderOff}
              />
              <>
                <ConfirmProvider>
                  <div
                    style={
                      sliderVisible ? { marginTop: 360 } : { marginTop: 40 }
                    }
                  >
                    <Products
                      products={products}
                      productTypes={productTypes}
                      categories={categories}
                      productColors={productColors}
                      productMetals={productMetals}
                      productGemstones={productGemstones}
                      pearlTypes={pearlTypes}
                      pearlSizes={pearlSizes}
                      productLengths={productLengths}
                      ringSizes={ringSizes}
                      productEras={productEras}
                      discounts={discounts}
                      onAddToCart={handleAddToCart}
                      handleUpdateCartQty
                      setSelectedProduct={setSelectedProduct}
                      loggedIn={loggedIn}
                      cart={cart}
                    />
                  </div>
                </ConfirmProvider>
              </>
              <SiteFooter></SiteFooter>
            </div>
          }
        ></Route>
        <Route
          exact
          path="/products/productdetails"
          element={
            <div style={{ padding: "1rem" }}>
              <Navbar
                totalItems={cart ? cart.total_items : 0}
                handleDrawerToggle={handleDrawerToggle}
              />
              <PubProductDetails
                products={products}
                productTypes={productTypes}
                categories={categories}
                productColors={productColors}
                productMetals={productMetals}
                productGemstones={productGemstones}
                pearlTypes={pearlTypes}
                pearlSizes={pearlSizes}
                productLengths={productLengths}
                ringSizes={ringSizes}
                productEras={productEras}
                selectedProduct={selectedProduct}
                onAddToCart={handleAddToCart}
                discounts={discounts}
                handleAddToCart={handleAddToCart}
                loggedIn={loggedIn}
              />
              <SiteFooter></SiteFooter>
            </div>
          }
        ></Route>
        <Route
          exact
          path="/cart"
          element={
            <>
              <Navbar
                totalItems={cart ? cart.total_items : 0}
                handleDrawerToggle={handleDrawerToggle}
              />
              <Cart
                cart={cart}
                products={products}
                onUpdateCartQty={handleUpdateCartQty}
                onRemoveFromCart={handleRemoveFromCart}
                onEmptyCart={handleEmptyCart}
              />
            </>
          }
        ></Route>
        <Route
          path="/checkout"
          exact
          element={
            <>
              <Navbar
                totalItems={cart ? cart.total_items : 0}
                handleDrawerToggle={handleDrawerToggle}
              />
              <Checkout
                cart={cart}
                setCheckedCart={setCheckedCart}
                onCaptureCheckout={handleCaptureCheckout}
              />
            </>
          }
        ></Route>
        <Route
          path="/success/:session_id"
          //exact
          element={
            <>
              <Navbar
                totalItems={cart ? cart.total_items : 0}
                handleDrawerToggle={handleDrawerToggle}
              />
              <PaySuccess handleStripeSession={handleStripeSession} />
            </>
          }
        ></Route>
        <Route
          path="/cancel"
          exact
          element={
            <>
              <Navbar
                totalItems={cart ? cart.total_items : 0}
                handleDrawerToggle={handleDrawerToggle}
              />
              <PayCancel />
            </>
          }
        ></Route>
        <Route
          exact
          path="/cart/AskForProduct"
          element={
            <>
              <Navbar
                totalItems={cart ? cart.total_items : 0}
                handleDrawerToggle={handleDrawerToggle}
              />
              <ConfirmProvider>
                <AskForProduct product={selectedProduct} />
              </ConfirmProvider>
            </>
          }
        ></Route>
        <Route
          exact
          path="/contact/aboutus"
          element={
            <div style={{ padding: "1rem" }}>
              <Navbar
                totalItems={cart ? cart.total_items : 0}
                handleDrawerToggle={handleDrawerToggle}
              />
              <AboutUs />
              <SiteFooter></SiteFooter>
            </div>
          }
        ></Route>
        {/* ... ADMIN ...*/}
        <Route
          path="/admin"
          exact
          element={
            loggedIn ? (
              <>
                <Layout />
                <AdminHome />
              </>
            ) : (
              <>
                <ConfirmProvider>
                  <LoginForm setLoggedIn={setLoggedIn} />
                </ConfirmProvider>
              </>
            )
          }
        ></Route>
        <Route
          path="/admin/categories"
          exact
          element={
            <>
              <Layout />
              <ShowCategories
                categories={categories}
                productTypes={productTypes}
                productTypeId={productTypeId}
                setProductTypeId={setProductTypeId}
                setRefreshCategories={setRefreshCategories}
              />
            </>
          }
        ></Route>
        <Route
          path="/admin/producttypes"
          exact
          element={
            <>
              <Layout />
              <ShowProductTypes
                productTypes={productTypes}
                setRefreshTypes={setRefreshTypes}
              />
            </>
          }
        ></Route>
        <Route
          path="/admin/products"
          exact
          element={
            <>
              <Layout />
              <ShowProducts
                setSelectedProduct={setSelectedProduct}
                products={products}
                productTypes={productTypes}
                categories={categories}
                productColors={productColors}
                productMetals={productMetals}
                productGemstones={productGemstones}
                pearlTypes={pearlTypes}
                pearlSizes={pearlSizes}
                productLengths={productLengths}
                ringSizes={ringSizes}
                productEras={productEras}
                discounts={discounts}
              />
            </>
          }
        ></Route>
        <Route
          path="/admin/products/add"
          exact
          element={
            <>
              <Layout />
              <ConfirmProvider>
                <ProductAdd
                  productTypes={productTypes}
                  categories={categories}
                  productColors={productColors}
                  productMetals={productMetals}
                  productGemstones={productGemstones}
                  pearlTypes={pearlTypes}
                  pearlSizes={pearlSizes}
                  productLengths={productLengths}
                  ringSizes={ringSizes}
                  productEras={productEras}
                  discounts={discounts}
                  newProductId={newProductId}
                  setNewProductId={setNewProductId}
                  setRefreshProducts={setRefreshProducts}
                ></ProductAdd>
              </ConfirmProvider>
            </>
          }
        ></Route>
        <Route
          path="/admin/products/skusearch"
          exact
          element={
            <>
              <Layout />
              <ConfirmProvider>
                <SKUDialog
                  products={products}
                  setSelectedProduct={setSelectedProduct}
                ></SKUDialog>
              </ConfirmProvider>
            </>
          }
        ></Route>
        <Route
          path="admin/products/productdetails"
          exact
          element={
            <>
              <Layout />
              <ProductDetails
                selectedProduct={selectedProduct}
                productTypes={productTypes}
                categories={categories}
                productColors={productColors}
                productMetals={productMetals}
                productGemstones={productGemstones}
                pearlTypes={pearlTypes}
                pearlSizes={pearlSizes}
                productLengths={productLengths}
                productEras={productEras}
                discounts={discounts}
                newProductId={newProductId}
                setNewProductId={setNewProductId}
                setImageType={setImageType}
              ></ProductDetails>
            </>
          }
        ></Route>
        <Route
          path="/admin/products/productedit"
          exact
          element={
            <>
              <Layout />
              <ConfirmProvider>
                <ProductEdit
                  selectedProduct={selectedProduct}
                  productTypes={productTypes}
                  categories={categories}
                  productColors={productColors}
                  productMetals={productMetals}
                  productGemstones={productGemstones}
                  pearlTypes={pearlTypes}
                  pearlSizes={pearlSizes}
                  productLengths={productLengths}
                  productEras={productEras}
                  discounts={discounts}
                  setRefreshProducts={setRefreshProducts}
                ></ProductEdit>
              </ConfirmProvider>
            </>
          }
        ></Route>
        <Route
          path="/admin/shoppingoptions"
          exact
          element={
            <>
              <Layout />
              <ShowShoppingOptions
                productColors={productColors}
                setRefreshColors={setRefreshColors}
                productMetals={productMetals}
                setRefreshMetals={setRefreshMetals}
                productGemstones={productGemstones}
                setRefreshGemstones={setRefreshGemstones}
                productLengths={productLengths}
                setRefreshLengths={setRefreshLengths}
                pearlTypes={pearlTypes}
                setRefreshPearlTypes={setRefreshPearlTypes}
                pearlSizes={pearlSizes}
                setRefreshPearlSizes={setRefreshPearlSizes}
                ringSizes={ringSizes}
                setRefreshRingSizes={setRefreshRingSizes}
                productEras={productEras}
                setRefreshProductEras={setRefreshProductEras}
                discounts={discounts}
                setRefreshDiscounts={setRefreshDiscounts}
              />
            </>
          }
        ></Route>
        <Route
          path="/admin/products/addimages"
          exact
          element={
            <>
              <Layout />
              <ProductWithUploader
                newProductId={newProductId}
                productId={selectedProduct.ProductId}
                imageType={imageType}
                setImageType={setImageType}
                setRefreshProducts={setRefreshProducts}
              />
            </>
          }
        ></Route>
      </Routes>
    </>
  );
};

export default App;
