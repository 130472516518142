import React from "react";
import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from "@material-ui/core";

import useStyles from "./styles";

const CartItem = ({ item, products, onUpdateCartQty, onRemoveFromCart }) => {
  const classes = useStyles();

  const handleUpdateCartQty = (lineItemId, newQuantity) =>
    onUpdateCartQty(lineItemId, newQuantity);

  const handleRemoveFromCart = (lineItemId) => onRemoveFromCart(lineItemId);

  //----------------------------------------------
  const getInventory = (SKU) => {
    let prod = products.filter((product) => product.SKU === SKU);
    return prod[0].Quantity;
  };

  let inventory = getInventory(item.sku);

  console.log("Product Inventory: " + inventory);
  return (
    <Card className="cart-item">
      <CardMedia
        image={process.env.REACT_APP_API_HOST + item.imageUrl}
        alt={item.productName}
        className={classes.media}
      />
      <CardContent className={classes.cardContent}>
        <Typography variant="h6">{item.productName}</Typography>
        <Typography variant="h6">{item.total}</Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <div className={classes.buttons}>
          <Button
            type="button"
            size="small"
            onClick={() =>
              handleUpdateCartQty(item.cartItemId, item.quantity - 1)
            }
          >
            <Typography variant="h6">-</Typography>
          </Button>
          <Typography variant="h6">&nbsp;{item.quantity}&nbsp;</Typography>
          <Button
            type="button"
            size="small"
            onClick={() =>
              handleUpdateCartQty(item.cartItemId, item.quantity + 1)
            }
            hidden={!(inventory > item.quantity)}
          >
            <Typography variant="h6">+</Typography>
          </Button>
        </div>
        <Button
          variant="contained"
          type="button"
          color="secondary"
          onClick={() => handleRemoveFromCart(item.cartItemId)}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};

export default CartItem;
