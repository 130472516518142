import React, { useState } from "react";

import { ConfirmProvider } from "material-ui-confirm";

import { Divider, Container } from "@material-ui/core";

import useStyles from "./styles";

import ProductGemstoneAdd from "./ProductGemstoneAdd";
import ProductGemstoneEdit from "./ProductGemstoneEdit";
import ProductGemstoneList from "./ProductGemstoneList";

const ShowProductGemstone = ({ productGemstones, setRefreshGemstones }) => {
  const classes = useStyles();

  //used for choosing the right control for the action
  const actions = {
    list: "list",
    add: "add",
    update: "update",
  };

  const [action, setAction] = useState(actions.list);
  const [selectedGemstone, setSelectedGemstone] = useState();

  if (action === actions.list) {
    return (
      <Container justify="center">
        <div className={classes.toolbar} />
        <Divider></Divider>
        <p></p>
        <ProductGemstoneList
          productGemstones={productGemstones}
          actions={actions}
          setAction={setAction}
          selectedGemstone={selectedGemstone}
          setSelectedGemstone={setSelectedGemstone}
        ></ProductGemstoneList>
      </Container>
    );
  } else if (action === actions.add) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <ProductGemstoneAdd
            productGemstones={productGemstones}
            actions={actions}
            setAction={setAction}
            setRefreshGemstones={setRefreshGemstones}
          ></ProductGemstoneAdd>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.update) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <ProductGemstoneEdit
            productGemstones={productGemstones}
            actions={actions}
            setAction={setAction}
            setSelectedGemstone={setSelectedGemstone}
            selectedGemstone={selectedGemstone}
            setRefreshGemstones={setRefreshGemstones}
          ></ProductGemstoneEdit>
        </Container>
      </ConfirmProvider>
    );
  }
};

export default ShowProductGemstone;
