import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import { useConfirm } from "material-ui-confirm";
import { Box, Pagination } from "@mui/material";

import { Helmet } from "react-helmet";

import Product from "./Product/Product";
import ShoppingOptions from "../ShoppingOptions/ShoppingOptions";

//import Pagination from "../Pagination/index";
import useStyles from "./styles";

const Products = ({
  products,
  productTypes,
  categories,
  productColors,
  productMetals,
  productGemstones,
  pearlTypes,
  pearlSizes,
  ringSizes,
  productLengths,
  productEras,
  discounts,
  setSelectedProduct,
  onAddToCart,
  cart,
}) => {
  //----

  const classes = useStyles();
  const confirm = useConfirm();

  //HARD CODED TO BE FIXED
  const pageSize = 8;
  //const pageSize = process.env.REACT_APP_PAGESIZE;

  const filterView = () => {
    let filteredData = [...products];

    if (filters.length > 0) {
      for (let i = 0; i < filters.length; i++) {
        filteredData = filteredData.filter((product) => {
          if (filters[i].filterName === "categoryId") {
            return product.CategoryId == filters[i].id;
          } else if (filters[i].filterName === "colorId") {
            return product.ColorId == filters[i].id;
          } else if (filters[i].filterName === "metalId") {
            return product.MetalId == filters[i].id;
          } else if (filters[i].filterName === "gemstoneId") {
            return product.GemstoneId == filters[i].id;
          } else if (filters[i].filterName === "pearlTypeId") {
            return product.PearlTypeId == filters[i].id;
          } else if (filters[i].filterName === "pearlSizeId") {
            return product.PearlSizeId == filters[i].id;
          } else if (filters[i].filterName === "lengthId") {
            return product.LengthId == filters[i].id;
          } else if (filters[i].filterName === "eraId") {
            return product.ProductEraId == filters[i].id;
          } else if (filters[i].filterName === "discountId") {
            return product.DiscountId == filters[i].id;
          }
        });
      }
    } else {
      //bring back initial data
      filteredData = [...products];
    }
    if (filteredData.length > 0) {
      if (filterRemoved !== "") {
        //remove the name of the removed filter if any
        setFilterRemoved("");
      }
      setFilteredProducts(filteredData);
      setPagination({ count: filteredData.length, from: 0, to: pageSize });
      setProductsView(filteredData.slice(pagination.from, pagination.to));
    } else if (filters.length > 0) {
      //set filter removed
      setFilterRemoved(filters[filters.length - 1].filterName);
      setFilters(filters.slice(0, -1));
      confirm({
        title: `Products selection`,
        description: `No product found to match. Please make another selection.`,
        cancellationText: ``,
      }).then(() => {
        return;
      });
      return;
    }
  };

  //------ State ----------------
  const [filters, setFilters] = useState([], filterView);
  const [filterRemoved, setFilterRemoved] = useState("");
  const [productTypeId, setProductTypeId] = useState(
    process.env.REACT_APP_PRODUCT_TYPE_ID_MAIN
  );
  const [categoryId, setCategoryId] = useState("");
  const [colorId, setColorId] = useState("");
  const [metalId, setMetalId] = useState("");
  const [gemstoneId, setGemstoneId] = useState("");
  const [pearlTypeId, setPearlTypeId] = useState("");
  const [pearlSizeId, setPearlSizeId] = useState("");
  const [lengthId, setLengthId] = useState("");
  const [productEraId, setProductEraId] = useState("");
  const [discountId, setDiscountId] = useState("");
  //-------------Pagination-----------------------
  //const [pageSize, setPageSize] = useState(process.env.REACT_APP_PAGESIZE);
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize,
  });
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productsView, setProductsView] = useState([]);
  const [resetFilters, setResetFilters] = useState(false);

  //------ End State ------------

  useEffect(() => {
    setFilteredProducts([...products]);
    setPagination({ ...pagination, count: filteredProducts.length });
    setProductsView(filteredProducts.slice(pagination.from, pagination.to));

    filterView();
    /*     if (productTypes.length > 0) {
      setProductTypeId(productTypes[0].ProductTypeId);
    } */
  }, [products, productTypes, filters]);

  useEffect(() => {
    setPagination({ ...pagination, count: filteredProducts.length });
    setProductsView(filteredProducts.slice(pagination.from, pagination.to));
  }, [filteredProducts, pagination.from, pagination.to]);

  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;
    setPagination({ ...pagination, from: from, to: to });
  };

  // FILTERS
  /*     
    1. define filters array (of objects {filter, id}) and set it in this.state.
    2. each time when a selection changes:
      a) add the selected index to the indexes if it is not there
      b) iterate through indexes array and filter out from the datasource
      the elements that do not match any of the indexes in the filters
      c) make sure that everything gets refreshed when done */
  //---------------------------------------

  //---- FILTERS ----
  const handleFiltersChange = (filterName, id) => {
    //alert("Selected id: " + filterName + " with id: " + id);
    // it's important to not mutate state directly, so here we are creating a copy of the current state using the spread syntax
    /*     const updateFilters = [
      // copy the current filters state
      ...filters,
      // now you can add a new object to add to the array
      {
        // add the id
        id: id,
        // adding a new filter name
        filterName: filterName,
      },
    ]; */

    let updateFilters = [
      // copy the current filters state
      ...filters,
    ];

    //if filters does not contain the filter name add
    //if the name is present, replace the value if value greater than 0
    //if the name is present and value is 0, remove filter
    let found = false;
    if (updateFilters.length > 0) {
      if (id === "0") {
        //remove existing filter
        //alert("Remove filter: " + filterName);
      }
      for (let i = 0; i < updateFilters.length; i++) {
        if (updateFilters[i].filterName === filterName) {
          updateFilters[i].id = id;
          found = true;
          //remove filter with id 0
          if (id === "0") {
            updateFilters = updateFilters.filter(
              //(filter) => filter.filterName != "categoryId"
              (filter) => filter.id != id
            );
          }
          break;
        }
      }
      if (!found) {
        if (id !== "0") {
          updateFilters = [
            // copy the current filters state
            ...filters,
            // now you can add a new object to add to the array
            {
              // add the id
              id: id,
              // adding a new filter name
              filterName: filterName,
            },
          ];
        }
      }
    } else {
      if (id !== "0") {
        updateFilters = [
          // copy the current filters state
          ...filters,
          // now you can add a new object to add to the array
          {
            // add the id
            id: id,
            // adding a new filter name
            filterName: filterName,
          },
        ];
      }
    }
    // update the state to the updatedFilters
    setFilters(updateFilters);
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 8fr",
      }}
    >
      <Helmet>
        <title>Manuel Fridman's Jewelry Products</title>
        <meta
          name="description"
          content="Explore our jewelry offer and prices"
        />
      </Helmet>
      <Grid container justify="center" spacing={4}>
        <Grid item>
          <ShoppingOptions
            productTypes={productTypes}
            productTypeId={productTypeId}
            setProductTypeId={setProductTypeId}
            //--------------------------------
            categories={categories}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            //--------------------------------
            productColors={productColors}
            colorId={colorId}
            setColorId={setColorId}
            //--------------------------------
            productMetals={productMetals}
            metalId={metalId}
            setMetalId={setMetalId}
            //--------------------------------
            productGemstones={productGemstones}
            gemstoneId={gemstoneId}
            setGemstoneId={setGemstoneId}
            //--------------------------------
            pearlTypes={pearlTypes}
            pearlTypeId={pearlTypeId}
            setPearlTypeId={setPearlTypeId}
            //--------------------------------
            pearlSizes={pearlSizes}
            pearlSizeId={pearlSizeId}
            setPearlSizeId={setPearlSizeId}
            //--------------------------------
            productLengths={productLengths}
            lengthId={lengthId}
            setLengthId={setLengthId}
            //--------------------------------
            productEras={productEras}
            productEraId={productEraId}
            setProductEraId={setProductEraId}
            //--------------------------------
            discounts={discounts}
            discountId={discountId}
            setDiscountId={setDiscountId}
            //-------------------------------
            handleFiltersChange={handleFiltersChange}
            filterRemoved={filterRemoved}
            admin={false}
            //-------------------------------
          ></ShoppingOptions>
        </Grid>
      </Grid>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container justify="center" spacing={4}>
          {productsView.map((product) => (
            <Grid key={product.ProductId} item xs={12} sm={6} md={4} lg={3}>
              <Product
                product={product}
                ringSizes={ringSizes}
                setSelectedProduct={setSelectedProduct}
                onAddToCart={onAddToCart}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          justifyContent={"center"}
          alignItems="center"
          display={"flex"}
          sx={{
            margin: "20px 0px",
          }}
        >
          <Pagination
            count={Math.ceil(pagination.count / pageSize)}
            onChange={handlePageChange}
          />
        </Box>
      </main>
    </div>
  );
};

export default Products;
