import React, { useState } from "react";

import {
  Typography,
  Divider,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  FormControl,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import useStyles from "./styles";

const ProductGemstoneList = (props) => {
  const classes = useStyles();

  const [editEnabled, setEditEnabled] = useState(false);
  const [selectedGemstoneId, setSelectedGemstoneId] = useState(0);

  const handleGemstoneChange = (event) => {
    setEditEnabled(true);
    setSelectedGemstoneId(event.target.value);
  };

  const handleAddNew = () => {
    props.setAction(props.actions.add);
  };

  const handleEditGemstone = (event) => {
    var selected = getSelectedGemstone();
    props.setSelectedGemstone(selected);
    props.setAction(props.actions.update);
  };

  const getSelectedGemstone = () => {
    var selected;
    var id = getSelectedGemstoneId();
    selected = props.productGemstones.filter(
      (selectedGemstone) => selectedGemstone.GemstoneId === id
    );
    return selected;
  };

  const getSelectedGemstoneId = () => {
    if (selectedGemstoneId == null) {
      return null;
    } else if (selectedGemstoneId != null) {
      return selectedGemstoneId;
    }
  };

  return (
    <Card className={classes.root}>
      {/* <CardHeader title="Product Colors"></CardHeader> */}
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <p></p>
          <Typography variant="h6">Product Gemstones</Typography>
          <Select
            width="150px;"
            variant="standard"
            autoWidth={true}
            labelId="gemstone-select-label"
            id="select-select"
            value={selectedGemstoneId}
            label="Product Gemstones"
            onChange={handleGemstoneChange}
          >
            <MenuItem disabled value="">
              <em>Please Select</em>
            </MenuItem>
            {props.productGemstones.map((item) => {
              if (item.GemstoneId != null) {
                return (
                  <MenuItem
                    value={item.GemstoneId}
                    key={item.GemstoneId}
                    fullWidth
                  >
                    {item.Description}
                  </MenuItem>
                );
              } else {
                return null;
              }
            })}
          </Select>
        </FormControl>

        <Divider variant="middle"></Divider>
        <div style={{ height: "40px" }}></div>
        <Typography variant="h6">
          Add a new gemstone or edit a selected gemstone.
        </Typography>
        <p />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleAddNew}
          >
            Add New
          </Button>
          <Button
            disabled={!editEnabled}
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleEditGemstone}
          >
            Edit Selected
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProductGemstoneList;
