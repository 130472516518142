import React, { useState } from "react";

import {
  Typography,
  Divider,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  FormControl,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import useStyles from "./styles";

const ProductColorList = (props) => {
  const classes = useStyles();

  const [editEnabled, setEditEnabled] = useState(false);
  const [selectedColorId, setSelectedColorId] = useState(0);

  const handleColorChange = (event) => {
    setEditEnabled(true);
    setSelectedColorId(event.target.value);
  };

  const handleAddNew = () => {
    props.setAction(props.actions.add);
  };

  const handleEditColor = () => {
    var selected = getSelectedColor();
    props.setSelectedColor(selected);
    props.setAction(props.actions.update);
  };

  const getSelectedColor = () => {
    var selected;
    var id = getSelectedColorId();
    selected = props.productColors.filter(
      (selectedColor) => selectedColor.ColorId === id
    );
    return selected;
  };

  const getSelectedColorId = () => {
    if (selectedColorId == null) {
      return null;
    } else if (selectedColorId != null) {
      return selectedColorId;
    }
  };

  return (
    <Card className={classes.root}>
      {/* <CardHeader title="Product Colors"></CardHeader> */}
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <p></p>
          <Typography variant="h6">Product Colors</Typography>
          <Select
            width="150px;"
            variant="standard"
            autoWidth={true}
            labelId="color-select-label"
            id="select-select"
            value={selectedColorId}
            label="Product Color"
            onChange={handleColorChange}
          >
            <MenuItem disabled value="">
              <em>Please Select</em>
            </MenuItem>
            {props.productColors.map((item) => {
              return (
                <MenuItem value={item.ColorId} key={item.ColorId} fullWidth>
                  {item.ColorName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Divider variant="middle"></Divider>
        <div style={{ height: "40px" }}></div>
        <Typography variant="h6">
          Add a new color or edit a selected color.
        </Typography>
        <p />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleAddNew}
          >
            Add New
          </Button>
          <Button
            disabled={!editEnabled}
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleEditColor}
          >
            Edit Selected
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProductColorList;
