import React from "react";
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";

import DetailsIcon from "@material-ui/icons/Details";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

import useStyles from "./styles";

const Product = ({ product, setSelectedProduct }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let imageUrl = process.env.REACT_APP_API_HOST + product.PrimaryImageUrl;

  const handleOnClick = () => {
    setSelectedProduct(product);
    navigate(`/admin/products/productdetails`);
  };

  return (
    <Card
      mt="10%"
      className={classes.root}
      sx={{
        width: 400,
        height: 400,
        backgroundColor: "primary.dark",
        transition: "transform 0.15s ease-in-out",
        "&:hover": {
          backgroundColor: "primary.main",
          opacity: [0.9, 0.8, 0.7],
          transform: "scale3d(1.05, 1.05, 1)",
        },
      }}
    >
      <CardMedia
        className={classes.media}
        //className={classes.media_details_small}
        image={imageUrl}
        title={product.ProductName}
        sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
      />
      <CardContent>
        <div className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {product.ProductName}
          </Typography>
        </div>
        <div>
          <Typography gutterBottom variant="h5" component="h2">
            Price: ${product.Price}
          </Typography>
        </div>
        <div>
          <Typography
            dangerouslySetInnerHTML={{ __html: product.Description }}
            variant="body1"
            color="textSecondary"
            component="p"
          />
          <Tooltip title="Details">
            <DetailsIcon
              //onMouseOver={handleMouseOver}
              onClick={handleOnClick}
              style={{ cursor: "pointer" }}
            ></DetailsIcon>
          </Tooltip>
          <span style={{ cursor: "pointer" }} onClick={handleOnClick}>
            {"Details"}
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

export default Product;
