import React, { useState } from "react";

import {
  Grid,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  CardHeader,
  FormControl,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import useStyles from "./styles";
import { useConfirm } from "material-ui-confirm";

const CategoryList = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [mainCatId, setMainCatId] = useState();
  const [subCatId, setSubCatId] = useState();
  const [editEnabled, setEditEnabled] = useState(false);

  const handleCatChange = (event) => {
    setMainCatId(event.target.value);
    setEditEnabled(true);
    var typeId = getProductTypeId(event.target.value);
    props.setProductTypeId(typeId);
    //return alert("Category changed: " + event.target.value);
  };

  const handleSubCatChange = (event) => {
    if (props.productTypeId === 0) {
      return alert("please select a product type");
    }
    setSubCatId(event.target.value);
    //return alert("Subcategory changed: " + event.target.value);
  };

  const getSelectedCatId = () => {
    if (mainCatId == null && subCatId == null) {
      alert("no category selection");
      return null;
    } else if (mainCatId != null && subCatId == null) {
      //alert("selected id is: " + mainCatId);
      return mainCatId;
    } else if (mainCatId != null && subCatId != null) {
      //alert("selected id is: " + subCatId);
      return subCatId;
    }
  };

  const getSelectedCategory = () => {
    var selected;
    var id = getSelectedCatId();
    selected = props.categories.filter(
      (category) => category.CategoryId === id
    );
    return selected;
  };

  const getProductTypeId = (catId) => {
    var mainCategory = props.categories.filter(
      (category) => category.CategoryId === catId
    );
    return mainCategory[0].ProductTypeId;
  };

  const handleTypeChange = (event) => {
    props.setProductTypeId(event.target.value);
    props.setRefreshCategories(true);
  };

  const handleAddNew = () => {
    if (props.productTypeId === 0) {
      confirm({
        title: `A selected product type is required`,
        description: `Please select a product type from the drop-down list.`,
        cancellationText: ``,
      }).then(() => {
        return;
      });
      return;
    }
    props.setAction(props.actions.add);
  };

  const handleEditCat = () => {
    var selected = getSelectedCategory();
    props.setSelectedCategory(selected);
    props.setAction(props.actions.update);
  };

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item md={6}>
        <Card className={classes.padding}>
          <CardHeader title="Product Categories"></CardHeader>
          <CardContent>
            <Grid container spacing={1} justify="center">
              <Grid item xs={12} sm={6} md={12}>
                <Grid item spacing={4} zeroMinWidth>
                  <Typography variant="h7">
                    Please note that Categories are relative to Product Type.
                  </Typography>
                </Grid>
                <Grid item spacing={4} zeroMinWidth>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <p></p>
                    <Typography variant="h6">Product Type List</Typography>
                    <Select
                      width="150px;"
                      variant="standard"
                      autoWidth={true}
                      labelId="productType-select-label"
                      id="productType-select"
                      value={props.productTypeId}
                      label="Product Type"
                      onChange={handleTypeChange}
                    >
                      <MenuItem disabled value="">
                        <em>Please Select</em>
                      </MenuItem>
                      {props.productTypes.map((item) => {
                        return (
                          <MenuItem
                            value={item.ProductTypeId}
                            key={item.ProductTypeId}
                            fullWidth
                          >
                            {item.Type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item spacing={4} zeroMinWidth>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <p></p>
                      <Typography variant="h6">Main Category List</Typography>
                      <Select
                        width="150px;"
                        variant="standard"
                        autoWidth={true}
                        labelId="category-select-label"
                        id="select-select"
                        value={mainCatId}
                        label="Categories"
                        onChange={handleCatChange}
                      >
                        <MenuItem disabled value="">
                          <em>Please Select</em>
                        </MenuItem>

                        {props.categories.map((item) => {
                          if (item.ParentId === null) {
                            return (
                              <MenuItem
                                value={item.CategoryId}
                                key={item.CategoryId}
                                fullWidth
                              >
                                {item.Title}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item spacing={4} zeroMinWidth>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <p></p>
                      <Typography variant="h6">Subcategory List</Typography>
                      <Select
                        displayEmpty={false}
                        width="150px;"
                        variant="standard"
                        autoWidth={true}
                        labelId="category-select-label"
                        id="select-select"
                        value={subCatId}
                        defaultValue={0}
                        label="Categories"
                        onChange={handleSubCatChange}
                      >
                        {props.categories.map((item) => {
                          //return subcategories only
                          if (
                            item.ParentId !== null &&
                            item.ParentId === mainCatId
                          ) {
                            return (
                              <MenuItem
                                value={item.CategoryId}
                                key={item.CategoryId}
                                fullWidth
                              >
                                {item.Title}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item spacing={4} zeroMinWidth justify="center">
                {/* <Divider variant="middle"></Divider> */}
                <div style={{ height: "40px" }}></div>
                <Typography variant="h6">
                  Add a new Category or edit a selected Category.
                </Typography>
                <Typography variant="h7">
                  Subcategories are categories with a parent category.
                </Typography>
                <p />
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    onClick={handleAddNew}
                  >
                    Add New
                  </Button>
                  <Button
                    disabled={!editEnabled}
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    onClick={handleEditCat}
                  >
                    Edit Selected
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CategoryList;
