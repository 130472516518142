import React, { useState } from "react";

import {
  Typography,
  Divider,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  FormControl,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import useStyles from "./styles";

const ProductEraList = (props) => {
  const classes = useStyles();

  const [editEnabled, setEditEnabled] = useState(false);
  const [selectedEraId, setSelectedEraId] = useState(0);

  const handleProductEraChange = (event) => {
    setEditEnabled(true);
    setSelectedEraId(event.target.value);
  };

  const handleAddNew = (event) => {
    props.setAction(props.actions.add);
  };

  const handleEditProductEra = (event) => {
    var selected = getSelectedEra();
    props.setSelectedEra(selected);
    props.setAction(props.actions.update);
  };

  const getSelectedEra = () => {
    var selected;
    var id = getSelectedEraId();
    selected = props.productEras.filter(
      (selectedEra) => selectedEra.ProductEraId === id
    );
    return selected;
  };

  const getSelectedEraId = () => {
    if (selectedEraId == null) {
      return null;
    } else if (selectedEraId != null) {
      return selectedEraId;
    }
  };

  return (
    <Card className={classes.root}>
      {/* <CardHeader title="Pearl Sizes"></CardHeader> */}
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <p></p>
          <Typography variant="h6">Product Era</Typography>
          <Select
            width="150px;"
            variant="standard"
            autoWidth={true}
            labelId="era-select-label"
            id="select-select"
            value={selectedEraId}
            label="Product Era"
            onChange={handleProductEraChange}
          >
            <MenuItem disabled value="">
              <em>Please Select</em>
            </MenuItem>
            {props.productEras.map((item) => {
              if (item.ProductEraId != null) {
                return (
                  <MenuItem
                    value={item.ProductEraId}
                    key={item.ProductEraId}
                    fullWidth
                  >
                    {item.EraDescription}
                  </MenuItem>
                );
              } else {
                return null;
              }
            })}
          </Select>
        </FormControl>

        <Divider variant="middle"></Divider>
        <div style={{ height: "40px" }}></div>
        <Typography variant="h6">
          Add a new era or edit a selected era.
        </Typography>
        <p />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleAddNew}
          >
            Add New
          </Button>
          <Button
            disabled={!editEnabled}
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleEditProductEra}
          >
            Edit Selected
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProductEraList;
