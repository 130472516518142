import React, { useState } from "react";

import {
  Typography,
  Divider,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  FormControl,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import useStyles from "./styles";

const DiscountList = (props) => {
  const classes = useStyles();

  const [editEnabled, setEditEnabled] = useState(false);
  const [selectedDiscountId, setSelectedDiscountId] = useState(0);

  const handleDiscountChange = (event) => {
    setEditEnabled(true);
    setSelectedDiscountId(event.target.value);
  };

  const handleAddNew = (event) => {
    props.setAction(props.actions.add);
  };

  const handleEditDiscount = (event) => {
    var selected = getSelectedDiscount();
    props.setSelectedDiscount(selected);
    props.setAction(props.actions.update);
  };

  const getSelectedDiscount = () => {
    var selected;
    var id = getSelectedDiscountId();
    selected = props.discounts.filter(
      (selectedDiscount) => selectedDiscount.DiscountId === id
    );
    return selected;
  };

  const getSelectedDiscountId = () => {
    if (selectedDiscountId == null) {
      return null;
    } else if (selectedDiscountId != null) {
      return selectedDiscountId;
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <p></p>
          <Typography variant="h6">Discounts</Typography>
          <Select
            width="150px;"
            variant="standard"
            autoWidth={true}
            labelId="discount-select-label"
            id="select-select"
            value={selectedDiscountId}
            label="Discount"
            onChange={handleDiscountChange}
          >
            <MenuItem disabled value="">
              <em>Please Select</em>
            </MenuItem>
            {props.discounts.map((item) => {
              if (item.DiscountId != null) {
                return (
                  <MenuItem
                    value={item.DiscountId}
                    key={item.DiscountId}
                    fullWidth
                  >
                    {item.Name}
                  </MenuItem>
                );
              } else {
                return null;
              }
            })}
          </Select>
        </FormControl>

        <Divider variant="middle"></Divider>
        <div style={{ height: "40px" }}></div>
        <Typography variant="h6">
          Add a new discount or edit a selected discount.
        </Typography>
        <p />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleAddNew}
          >
            Add New
          </Button>
          <Button
            disabled={!editEnabled}
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleEditDiscount}
          >
            Edit Selected
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DiscountList;
