import React from "react";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Button,
  CardHeader,
  Box,
} from "@material-ui/core";

import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { TextArea } from "semantic-ui-react";
import { Typography } from "@mui/material";

import { Helmet } from "react-helmet";

import useStyles from "./styles";

//validation schema
let validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
});

const AskForProduct = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let imageUrl = process.env.REACT_APP_API_HOST + props.product.PrimaryImageUrl;
  const initialValues = {
    product: props.product
      ? props.product.ProductName + ", SKU: " + props.product.SKU
      : "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    comments: "",
  };

  const confirm = useConfirm();

  const onSubmit = async (values) => {
    confirm({
      title: "Send product request",
      description: `An email message will be sent to fmjewellers.com`,
    })
      .then(() => {
        //post the values to api
        fetch(process.env.REACT_APP_API_ASK_PRODUCT, {
          method: "POST",
          body: JSON.stringify({ ...values }),
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .then(navigate(`/`))
          .catch((err) => {
            console.log(err.message);
          });
      })
      .catch(() => console.log("Action cancelled."));
  };

  return (
    <Grid container justify="center">
      <Helmet>
        <title>
          {props.product.ProductName + ", SKU: " + props.product.SKU}
        </title>
        <meta name="description" content={props.product.Description} />
      </Helmet>
      <Grid item md={6}>
        <Card
          className={classes.root_details}
          xs={{
            width: 400,
            height: 400,
            backgroundColor: "primary.dark",
            transition: "transform 0.15s ease-in-out",
            "&:hover": {
              backgroundColor: "primary.main",
              opacity: [0.9, 0.8, 0.7],
              transform: "scale3d(1.05, 1.05, 1)",
            },
          }}
        >
          <CardMedia
            className={classes.media}
            image={imageUrl}
            title={props.product.ProductName}
            xs={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          />
          <CardContent>
            <div className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                {props.product.ProductName}
              </Typography>
              <Typography gutterBottom variant="h5" component="h2">
                ${props.product.Price}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card className={classes.padding}>
          <CardHeader title="Contact Us"></CardHeader>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ dirty, isValid, values, handleChange, handleBlur }) => {
              return (
                <Form>
                  <CardContent>
                    <Grid container spacing={1} justify="center">
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          label="Selected Product"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={1}
                          name="selectedProduct"
                          value={values.product}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="First Name"
                          variant="outlined"
                          fullWidth
                          name="firstname"
                          value={values.firstname}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Last Name"
                          variant="outlined"
                          fullWidth
                          name="lastname"
                          value={values.lastname}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Email Address"
                          variant="outlined"
                          fullWidth
                          name="email"
                          value={values.email}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          name="phone"
                          value={values.phone}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Comments"
                          variant="outlined"
                          fullWidth
                          name="comments"
                          value={values.comments}
                          multiline
                          rows={4}
                          component={TextField}
                        />
                      </Grid>
                    </Grid>
                    {/* //// */}
                    <CardActions>
                      <Button component={Link} variant="outlined" to="/">
                        Cancel
                      </Button>
                      <Button
                        disabled={!dirty || !isValid}
                        variant="contained"
                        color="primary"
                        type="Submit"
                        className={classes.button}
                      >
                        Submit
                      </Button>
                    </CardActions>
                  </CardContent>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AskForProduct;
