import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import ShoppingOptions from "../../../components/ShoppingOptions/ShoppingOptions";

import Product from "./Product";
import useStyles from "./styles";

//const sample = require("./Product/SampleData/Sample");

const ShowProducts = (props) => {
  const classes = useStyles();
  //------ State ----------------
  const [productTypeId, setProductTypeId] = useState(
    process.env.REACT_APP_PRODUCT_TYPE_ID_MAIN
  );
  const [categoryId, setCategoryId] = useState("");
  const [colorId, setColorId] = useState("");
  const [metalId, setMetalId] = useState("");
  const [gemstoneId, setGemstoneId] = useState("");
  const [pearlTypeId, setPearlTypeId] = useState("");
  const [pearlSizeId, setPearlSizeId] = useState("");
  const [lengthId, setLengthId] = useState("");
  //const [ringSizeId, setRingSizeId] = useState("");
  const [productEraId, setProductEraId] = useState("");
  const [discountId, setDiscountId] = useState("");
  //------ End State ------------

  //alert(JSON.stringify(sample.products));

  //if (!sample.products.length) return <p>Loading...</p>;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 8fr",
      }}
    >
      <Grid container justify="center" spacing={4}>
        <Grid item>
          {/*
          <ShoppingOptions
            productTypes={props.productTypes}
            productTypeId={productTypeId}
            setProductTypeId={setProductTypeId}
            //--------------------------------
            categories={props.categories}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            //--------------------------------
            productColors={props.productColors}
            colorId={colorId}
            setColorId={setColorId}
            //--------------------------------
            productMetals={props.productMetals}
            metalId={metalId}
            setMetalId={setMetalId}
            //--------------------------------
            productGemstones={props.productGemstones}
            gemstoneId={gemstoneId}
            setGemstoneId={setGemstoneId}
            //--------------------------------
            pearlTypes={props.pearlTypes}
            pearlTypeId={pearlTypeId}
            setPearlTypeId={setPearlTypeId}
            //--------------------------------
            pearlSizes={props.pearlSizes}
            pearlSizeId={pearlSizeId}
            setPearlSizeId={setPearlSizeId}
            //--------------------------------
            productLengths={props.productLengths}
            lengthId={lengthId}
            setLengthId={setLengthId}
            //--------------------------------
            //ringSizes={props.ringSizes}
            //ringSizeId={ringSizeId}
            //setRingSizeId={setRingSizeId} 
            //--------------------------------
            productEras={props.productEras}
            productEraId={productEraId}
            setProductEraId={setProductEraId}
            //--------------------------------
            discounts={props.discounts}
            discountId={discountId}
            setDiscountId={setDiscountId}
            //--------------------------------
            // prevent handleFiltersChange call
            admin={true}
          ></ShoppingOptions>
           */}
        </Grid>
      </Grid>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container justify="center" spacing={4}>
          {props.products.map((product) => (
            <Grid key={product.id} item xs={12} sm={6} md={4} lg={3}>
              <Product
                product={product}
                setSelectedProduct={props.setSelectedProduct}
              />
            </Grid>
          ))}
        </Grid>
      </main>
    </div>
  );
};

export default ShowProducts;
