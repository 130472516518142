import React from "react";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  CardHeader,
  FormControl,
} from "@material-ui/core";

import { useConfirm } from "material-ui-confirm";

import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

//validation schema
let validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  metaTitle: Yup.string().required("Required"),
  slug: Yup.string().required("Required"),
  content: Yup.string().required("Required"),
});

const CategoryAdd = (props) => {
  const initialValues = {
    productTypeId: props.productTypeId,
    parentId: null,
    title: "",
    metaTitle: "",
    slug: "",
    content: "",
    isActive: "",
  };
  const classes = useStyles();
  const confirm = useConfirm();

  const onSubmit = async (values) => {
    confirm({ description: `The new category will be saved to the database.` })
      .then(() => {
        //post the values to api
        fetch(process.env.REACT_APP_API_PRODUCT_CATEGORIES, {
          method: "POST",
          body: JSON.stringify({ ...values }),
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data) => {
            props.setRefreshCategories(true);
            props.setAction(props.actions.list);
          })
          .catch((err) => {
            console.log(err.message);
            props.setAction(props.actions.list);
          });
      })
      .catch(() => console.log("Action cancelled."));
  };

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item md={6}>
        <Card className={classes.padding}>
          <CardHeader title="Add Product Category"></CardHeader>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ dirty, isValid, values, handleChange, handleBlur }) => {
              return (
                <Form>
                  <CardContent>
                    <Grid item container spacing={1} justify="center">
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          hidden
                          label="Product Type Id"
                          variant="outlined"
                          fullWidth
                          name="productTypeId"
                          value={values.productTypeId}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="select-outlined-label">
                            Parent Category
                          </InputLabel>
                          <Select
                            labelId="select-outlined-label"
                            id="select-outlined"
                            label="Main Category"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.parentId}
                            name="parentId"
                          >
                            <MenuItem disabled value="">
                              <em>Please Select</em>
                            </MenuItem>
                            {props.categories.map((item) => {
                              if (item.ParentId == null) {
                                //return main categories only
                                return (
                                  <MenuItem
                                    value={item.CategoryId}
                                    key={item.CategoryId}
                                    fullWidth
                                  >
                                    {item.Title}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Title"
                          variant="outlined"
                          fullWidth
                          name="title"
                          value={values.title}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Meta Title"
                          variant="outlined"
                          fullWidth
                          name="metaTitle"
                          value={values.metaTitle}
                          component={TextField}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Slug"
                          variant="outlined"
                          fullWidth
                          name="slug"
                          value={values.slug}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Content"
                          variant="outlined"
                          fullWidth
                          name="content"
                          value={values.content}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="select-outlined-label">
                            Is Active
                          </InputLabel>
                          <Select
                            labelId="select-outlined-label"
                            id="select-outlined"
                            label="Is Active"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isActive}
                            name="isActive"
                          >
                            <MenuItem value="true">
                              <em>true</em>
                            </MenuItem>
                            <MenuItem value="false">
                              <em>false</em>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <CardActions>
                      <Button
                        component={Link}
                        variant="outlined"
                        to="/admin/categories"
                        onClick={() => {
                          props.setAction(props.actions.list);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!dirty || !isValid}
                        variant="contained"
                        color="primary"
                        type="Submit"
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </CardActions>
                  </CardContent>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CategoryAdd;
