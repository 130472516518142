import React, { useState } from "react";

import { ConfirmProvider } from "material-ui-confirm";

import { Divider, Container } from "@material-ui/core";

import useStyles from "./styles";

import PearlTypeAdd from "./PearlTypeAdd";
import PearlTypeEdit from "./PearlTypeEdit";
import PearlTypeList from "./PearlTypeList";

const ShowPearlType = ({ pearlTypes, setRefreshPearlTypes }) => {
  const classes = useStyles();

  //used for choosing the right control for the action
  const actions = {
    list: "list",
    add: "add",
    update: "update",
  };

  const [action, setAction] = useState(actions.list);
  const [selectedType, setSelectedType] = useState();

  if (action === actions.list) {
    return (
      <Container justify="center">
        <div className={classes.toolbar} />
        <Divider></Divider>
        <p></p>
        <PearlTypeList
          pearlTypes={pearlTypes}
          actions={actions}
          setAction={setAction}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        ></PearlTypeList>
      </Container>
    );
  } else if (action === actions.add) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <PearlTypeAdd
            pearlTypes={pearlTypes}
            actions={actions}
            setAction={setAction}
            setRefreshPearlTypes={setRefreshPearlTypes}
          ></PearlTypeAdd>
        </Container>
      </ConfirmProvider>
    );
  } else if (action === actions.update) {
    return (
      <ConfirmProvider>
        <Container justify="center">
          <div className={classes.toolbar} />
          <Divider></Divider>
          <PearlTypeEdit
            pearlTypes={pearlTypes}
            actions={actions}
            setAction={setAction}
            setSelectedType={setSelectedType}
            selectedType={selectedType}
            setRefreshPearlTypes={setRefreshPearlTypes}
          ></PearlTypeEdit>
        </Container>
      </ConfirmProvider>
    );
  }
};

export default ShowPearlType;
