import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  GridList,
  GridListTile,
  Divider,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@mui/material/Tooltip";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

//----------------------------------
/* import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers"; */
//----------------------------------

import useStyles from "./styles";

function ProductDetails(props) {
  var randomColor = require("randomcolor");
  let product = props.selectedProduct;

  const classes = useStyles();
  const navigate = useNavigate();
  let imageUrl = process.env.REACT_APP_API_HOST + product.PrimaryImageUrl;
  let imageUrlSc = process.env.REACT_APP_API_HOST + product.SecondaryImageUrl;

  const handleOnClick = (arg) => {
    if (arg + "" === "product") {
      navigate(`/admin/products/productedit`);
    } else {
      props.setImageType(arg + "");
      navigate(`/admin/products/addimages`);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      margin={2}
      justify="center"
      className="grid_container"
      //style={{ background: randomColor() }}
    >
      <Grid
        item
        xs={6}
        className="grid_item"
        //style={{ background: randomColor() }}
      >
        <Card className={classes.root_details}>
          <CardMedia
            className={classes.media_details}
            image={imageUrl}
            title={product.ProductName}
            sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          />
          <CardContent></CardContent>
        </Card>
        <GridList cellHeight={600} className={classes.gridList} cols={2}>
          <GridListTile key={1}>
            <Typography variant="h4>">Primary Image</Typography>
            <Tooltip title="Edit">
              <EditIcon
                //onMouseOver={handleMouseOver}
                onClick={() => handleOnClick("primary")}
                style={{ cursor: "pointer" }}
              ></EditIcon>
            </Tooltip>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleOnClick("primary")}
            >
              {"Edit"}
            </span>
            <Card className={classes.card_small}>
              <CardMedia
                className={classes.media_details_small}
                image={imageUrl}
                title={product.ProductName}
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
              />
              <CardContent></CardContent>
            </Card>
          </GridListTile>
          <GridListTile key={2}>
            <Typography variant="h4>">Secondary Image</Typography>
            <Tooltip title="Edit">
              <EditIcon
                //onMouseOver={handleMouseOver}
                onClick={() => handleOnClick("secondary")}
                style={{ cursor: "pointer" }}
              ></EditIcon>
            </Tooltip>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleOnClick("secondary")}
            >
              {"Edit"}
            </span>
            <Card className={classes.card_small}>
              <CardMedia
                className={classes.media_details_small}
                image={imageUrlSc}
                title={product.ProductName}
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
              />
              <CardContent></CardContent>
            </Card>
          </GridListTile>
        </GridList>
      </Grid>
      <Grid
        item
        xs={6}
        className="grid_item"
        //style={{ background: randomColor() }}
      >
        <Card className={classes.root_details}>
          <CardContent>
            <div className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                {product.ProductName}
              </Typography>
              <Tooltip title="Edit">
                <EditIcon
                  onClick={() => handleOnClick("product")}
                  style={{ cursor: "pointer" }}
                ></EditIcon>
              </Tooltip>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleOnClick("product")}
              >
                {"Edit"}
              </span>
            </div>
            <div>
              <Typography gutterBottom variant="h5" component="h2">
                <CurrencyTextField
                  label="Price"
                  variant="standard"
                  value={product.Price}
                  currencySymbol="$"
                  outputFormat="string"
                />
              </Typography>
            </div>
            <div>
              <Typography
                dangerouslySetInnerHTML={{ __html: product.Description }}
                variant="h6"
                color="textSecondary"
                component="p"
              />
            </div>
          </CardContent>
        </Card>
        <Card className={classes.root_details}>
          <CardContent>
            <div className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                Product Details
              </Typography>
            </div>
            <Divider></Divider>
            <div>
              <Typography variant="body1">
                PRODUCT TYPE: {product.Type}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">
                CATEGORY: {product.Category}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">SKU: {product.SKU}</Typography>
              <Divider></Divider>
              <Typography variant="body1">
                DESIGNER: {product.Designer}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">
                METAL: {product.MetalDescription}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">
                GEMSTONE: {product.GemstoneName}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">
                COLOR: {product.ColorName}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">SIZE: {product.Size}</Typography>
              <Divider></Divider>
              <Typography variant="body1">
                LENGTH: {product.LengthDescription}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">
                PEARL TYPE: {product.PearlType}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">
                PEARL SIZE: {product.PearlSize}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">
                PRODUCT ERA: {product.EraDescription}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">
                PRODUCT COMMENTS: {product.Comments}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">
                DISCOUNT: {product.DiscountDescription}
              </Typography>
              <Divider></Divider>
              <Typography variant="body1">
                IN STOCK: {product.Quantity}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ProductDetails;
