import React from "react";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
} from "@material-ui/core";

import { useConfirm } from "material-ui-confirm";

import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const initialValues = {
  username: "",
  password: "",
};

//validation schema
let validationSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

const LoginForm = (props) => {
  const classes = useStyles();

  const confirm = useConfirm();

  const onSubmit = async (values) => {
    confirm({
      description: `Logging in to the admin site.`,
    })
      .then(() => {
        //post the values to api
        fetch(process.env.REACT_APP_API_LOGIN, {
          method: "POST",
          body: JSON.stringify({ ...values }),
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            data[0].Admin ? props.setLoggedIn(true) : props.setLoggedIn(false);
          })
          .catch((err) => {
            console.log(err.message);
          });
      })
      .catch(() => console.log("Action cancelled."));
  };

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item md={6}>
        <Card
          className={classes.padding}
          style={{ backgroundColor: "#e6ffff" }}
        >
          <CardHeader title="Admin Login"></CardHeader>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ dirty, isValid, values, handleChange, handleBlur }) => {
              return (
                <Form>
                  <CardContent style={{ backgroundColor: "#f6ffff" }}>
                    <Grid container spacing={1} justify="center">
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="User Name"
                          variant="outlined"
                          fullWidth
                          name="username"
                          value={values.username}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          label="Password"
                          variant="outlined"
                          fullWidth
                          name="password"
                          value={values.password}
                          component={TextField}
                        />
                      </Grid>
                    </Grid>
                    {/* //// */}
                    <CardActions>
                      <Button component={Link} variant="outlined" to="/">
                        Cancel
                      </Button>
                      <Button
                        disabled={!dirty || !isValid}
                        variant="contained"
                        color="primary"
                        type="Submit"
                        className={classes.button}
                      >
                        Login
                      </Button>
                    </CardActions>
                  </CardContent>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
